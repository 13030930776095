import React from 'react';

import {useTranslation} from "react-i18next";
import moment from "moment/moment";

export default function CustomDateRangePicker(props) {
    const {t} = useTranslation();

    return (<>
        <div className="row">
            <div className={"col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"}>
                <div className="card crm-data-card">
                    <div className="text-right"></div>
                    <div className="card-body animated fadeIn fast">
                        <div className="row">
                            <div
                                className={"col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center"}>
                                {props.selectedTimeMode === 5 && <span>{t("Choose period")} </span>}
                                {props.selectedTimeMode !== 5 &&
                                    <a href="#" onClick={() => props.setSelectedTimeMode(5)}>
                                        <span>{t("Choose period")} </span>
                                    </a>}
                                |
                                {props.selectedTimeMode === 4 && <span> {t("Beginning of the year")} </span>}

                                {props.selectedTimeMode !== 4 && <a href="#" onClick={() => {
                                    props.setSelectedTimeMode(4);

                                    let startOfWeek = moment().subtract(1, 'year').startOf('month').toDate();
                                    let endOfWeek = moment().endOf('month').toDate();

                                    props.setStartAndEndDate(startOfWeek, endOfWeek);
                                }}>
                                    <span> {t("Beginning of the year")} </span>
                                </a>}
                                |
                                {props.selectedTimeMode === 3 && <span> {t("Last 3 months")} </span>}
                                {props.selectedTimeMode !== 3 && <a href="#" onClick={() => {
                                    props.setSelectedTimeMode(3);

                                    let startOfWeek = moment().subtract(3, 'month').startOf('month').toDate();
                                    let endOfWeek = moment().endOf('month').toDate();

                                    props.setStartAndEndDate(startOfWeek, endOfWeek);
                                }}>
                                    <span> {t("Last 3 months")} </span>
                                </a>}
                                |
                                {props.selectedTimeMode === 2 && <span> {t("This month")} </span>}
                                {props.selectedTimeMode !== 2 && <a href="#" onClick={() => {
                                    props.setSelectedTimeMode(2);

                                    let startOfWeek = moment().startOf('month').toDate();
                                    let endOfWeek = moment().endOf('month').toDate();

                                    props.setStartAndEndDate(startOfWeek, endOfWeek);
                                }}>
                                    <span> {t("This month")} </span>
                                </a>}
                                |
                                {props.selectedTimeMode === 1 && <span> {t("Last week")} </span>}
                                {props.selectedTimeMode !== 1 && <a href="#" onClick={() => {
                                    props.setSelectedTimeMode(1);

                                    let startOfWeek = moment().subtract(1, 'weeks').startOf('week').toDate();
                                    let endOfWeek = moment().subtract(1, 'weeks').endOf('week').toDate();

                                    props.setStartAndEndDate(startOfWeek, endOfWeek);
                                }}>
                                    <span> {t("Last week")} </span>
                                </a>}
                                |
                                {props.selectedTimeMode === 0 && <span> {t("Current week")}</span>}
                                {props.selectedTimeMode !== 0 && <a href="#" onClick={() => {
                                    props.setSelectedTimeMode(0);

                                    let startOfWeek = moment().startOf('week').toDate();
                                    let endOfWeek = moment().endOf('week').toDate();

                                    props.setStartAndEndDate(startOfWeek, endOfWeek);
                                }}>
                                    <span> {t("Current week")}</span>
                                </a>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}