import {CONFIG} from "../../config";
import axios from "axios";

async function GetAdvancedStatistics(signal,zone_a, zone_b, from_date, to_date) {
    const location = CONFIG.API_URL + "nutitee/getAdvancedStatistics/" + zone_a + "/" + zone_b + "/" + from_date + "/" + to_date;
    const settings = {
        signal: signal,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('bearer-token')}`,
        }
    };

    try {
        return await axios.get(location, settings);
    } catch (e) {
        return null;
    }
}

export default GetAdvancedStatistics;