import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import Map from "../../components/LeafletMap/Map"
import GetAdvancedStatistics from "../../queries/Nutitee/GetAdvancedStatistics";

export default function NutiteeMap(props) {
    const { t } = useTranslation();
    const [Points, setPoints] = useState([]);

    const getZoneIds = () => {
        if(props.spc[0] === 2 && props.spc[1] === 5) {
            return [5, 6];
        }
        if(props.spc[0] === 5 && props.spc[1] === 10) {
            return [7, 8];
        }
        if(props.spc[0] === 10 && props.spc[1] === 11) {
            return [4, 3];
        }
        if(props.spc[0] === 11 && props.spc[1] === 14) {
            return [1, 2];
        }
    }

    const scaleToGradient = (val, max) => {
        return (0.7 * (val / max));
    }

    useEffect(() => {
        const abortController = new AbortController();
        const signal = abortController.signal;
        fetchData(signal);

        return () => {};
    }, [props.startDate, props.endDate]);

    const fetchData = async (signal) => {
        let data= {};
        // 2 & 5
        await GetAdvancedStatistics(signal, 5, 6, props.startDate.toISOString(), props.endDate.toISOString()).then((result) => {
            data['2_5'] = {
                'average_a': result.data.data.average_a,
                'average_b': result.data.data.average_b,
                'total_avg': (parseFloat(result.data.data.average_a) + parseFloat(result.data.data.average_b)) / 2
            }
        });

        // 5 & 10
        await GetAdvancedStatistics(signal, 7, 8, props.startDate.toISOString(), props.endDate.toISOString()).then((result) => {
            data['5_10'] = {
                'average_a': result.data.data.average_a,
                'average_b': result.data.data.average_b,
                'total_avg': (parseFloat(result.data.data.average_a) + parseFloat(result.data.data.average_b)) / 2
            }
        });

        // 10 & 11
        await GetAdvancedStatistics(signal, 4, 3, props.startDate.toISOString(), props.endDate.toISOString()).then((result) => {
            data['10_11'] = {
                'average_a': result.data.data.average_a,
                'average_b': result.data.data.average_b,
                'total_avg': (parseFloat(result.data.data.average_a) + parseFloat(result.data.data.average_b)) / 2
            }
        });

        // 11 & 14
        await GetAdvancedStatistics(signal, 1, 2, props.startDate.toISOString(), props.endDate.toISOString()).then((result) => {
            data['11_14'] = {
                'average_a': result.data.data.average_a,
                'average_b': result.data.data.average_b,
                'total_avg': (parseFloat(result.data.data.average_a) + parseFloat(result.data.data.average_b)) / 2
            }
        });

        setPoints(drawPoints(data));
    }

    const drawPoints = (data) => {
        const points = [];
        points.push({ points: [
            [58.065694, 27.072083], //14
            [58.063172, 27.069845],
            [58.061687, 27.068298],
            [58.060202, 27.066890],
            [58.058669, 27.065709] //11
        ], intensity: scaleToGradient(data['11_14'].total_avg, 55), zoneA: data['11_14'].average_a, zoneB: data['11_14'].average_b });

        points.push({ points: [
            [58.058669, 27.065709], //11
            [58.058439, 27.065409],
            [58.058139, 27.064779],
            [58.057398, 27.062779],
            [58.056798, 27.061379],
            [58.056198, 27.060479],
            [58.055298, 27.059489],
            [58.054871, 27.058806] //10
        ], intensity: scaleToGradient(data['10_11'].total_avg, 55), zoneA: data['10_11'].average_a, zoneB: data['10_11'].average_b });

        points.push({ points: [
            [58.054871, 27.058806], //10
            [58.054771, 27.058566],
            [58.054645, 27.058166],
            [58.054045, 27.055766],
            [58.053665, 27.054726],
            [58.053515, 27.053646],
            [58.053455, 27.053376],
            [58.052985, 27.052206],
            [58.052905, 27.051926],
            [58.052875, 27.051606],
            [58.052901, 27.050222] //5
        ], intensity: scaleToGradient(data['5_10'].total_avg, 55), zoneA: data['5_10'].average_a, zoneB: data['5_10'].average_b });

        points.push({ points: [
            [58.052901, 27.050222], //5
            [58.052843, 27.048652],
            [58.052680, 27.046802],
            [58.052615, 27.045402],
            [58.052569, 27.044083] //2
        ], intensity: scaleToGradient(data['2_5'].total_avg, 55), zoneA: data['2_5'].average_a, zoneB: data['2_5'].average_b });

        return points;
    }


    return (
        <>
            <Map title={t("Heatmap")} line data={Points}/>
        </>
    );
}