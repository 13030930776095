import {createSlice} from '@reduxjs/toolkit'

export const nutiteeSlice = createSlice({
    name: 'nutitee', initialState: {
        value: 0,
    }, reducers: {
        showNutitee: (state) => {
            state.value = 1
        }, hideNutitee: (state) => {
            state.value = 0
        }
    },
})

export const {
    showNutitee
} = nutiteeSlice.actions

export default nutiteeSlice.reducer