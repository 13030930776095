import {CONFIG} from "../../config";
import axios from "axios";

async function SaveConfigQuery(deviceId, coreId, component, config) {
    const location = CONFIG.API_URL + "device_configuration/save/" + deviceId + "/" + coreId + "/" + component;
    const settings = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('bearer-token')}`,
        }
    };
    try {
        return await axios.post(location, config, settings);
    } catch (e) {
        return e;
    }
}

export default SaveConfigQuery;