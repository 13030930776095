import React, {useEffect, useState} from 'react';
import './GroupsTable.css';
import {useTranslation} from "react-i18next";
import DataTable from "react-data-table-component";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAdd, faCheck, faMultiply, faPencil, faRotate, faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {Button} from "react-bootstrap";
import {ButtonGroup} from "@mui/material";
import {useDispatch} from "react-redux";
import {disableLoading, enableLoading} from "../../features/loading/loadingSlice";
import GetGroupsQuery from "../../queries/Groups/GetGroups";
import withReactContent from "sweetalert2-react-content";
import swal from "sweetalert2";
import DeleteGroupQuery from "../../queries/Groups/DeleteGroup";
import AddGroupModal from "./Modals/AddGroupModal";


export default function GroupsTable(props) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [currentUser] = useState(props.currentUser);
    const [addModalShow, setAddModalShow] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [currentGroup] = useState(props.currentGroup);
    const [dataTableData, setDataTableData] = useState([]);
    const groupDeleteConfirmSwal = withReactContent(swal);
    const groupDeleteErrorMessage = withReactContent(swal);
    const groupDeleteConfirmMessage = withReactContent(swal)
    const columns = [{
        name: t('Name'), selector: row => row.name, sortable: true,
    }, {
        name: t('Description'), selector: row => row.description, sortable: true
    }, {
        name: t('Parent group'), selector: row => row.parent, sortable: true
    }, {
        name: t('Is administrator'), selector: row => row.admin, sortable: true, right: true
    }, {
        name: '', selector: row => row.buttons, sortable: false, right: true
    }];

    useEffect(() => {
        fetchGroups();
    }, []);


    const deleteGroup = (id) => {
        //fist show confirm
        groupDeleteConfirmSwal.fire({
            title: t("Are you sure?"), text: t("Group will be deleted"), icon: "error", showCancelButton: true,
        })
            .then(res => {
                if (res.isConfirmed) {
                    //then show loading
                    dispatch(enableLoading());

                    //generate
                    DeleteGroupQuery(id).then((response) => {
                        if (response.status !== 200 && response.status !== 201) {
                            dispatch(disableLoading());

                            let message = response.response.data?.message;

                            groupDeleteErrorMessage.fire({
                                title: <strong>{t("Error")}</strong>, html: <i>{t(message)}</i>, icon: 'error'
                            }).then();
                        } else {
                            dispatch(disableLoading());


                            groupDeleteConfirmMessage.fire({
                                title: <strong>{t("Success")}</strong>,
                                html: <i>{t("Group deleted!")}</i>,
                                icon: 'success'
                            }).then(() => fetchGroups());
                        }
                    });
                }
            });
    }

    const fetchGroups = () => {
        dispatch(enableLoading());
        setSelectedGroup(null);
        GetGroupsQuery().then((response) => {
            if (response.status === 200) {
                let groups = response.data.data;

                let dataTableArray = [];

                for (const [, value] of Object.entries(groups)) {
                    let row = {
                        id: value.id,
                        name: value.name,
                        description: value.description,
                        parent: value.parent?.name ?? "",
                        admin: value.is_admin ?
                            <span className="text-success"><FontAwesomeIcon icon={faCheck}/></span> :
                            <span className="text-danger"><FontAwesomeIcon icon={faMultiply}/></span>,
                        buttons: <ButtonGroup>
                            {(currentGroup.id !== value.id && value.id !== 1) &&
                                <button type="button" className="btn btn-danger btn-icon-square-sm"
                                        title={t("Delete group")}
                                        style={{marginRight: "2px"}}
                                        onClick={() => deleteGroup(value.id)}
                                >
                                    <FontAwesomeIcon icon={faTrash}/>
                                </button>}

                            {(value.id !== 1) && <button type="button" className="btn btn-primary btn-icon-square-sm"
                                                         title={t("Edit group")}
                                                         style={{marginRight: "2px"}}
                                                         onClick={() => {
                                                             setSelectedGroup(value);
                                                             setAddModalShow(true)
                                                         }}
                            >
                                <FontAwesomeIcon icon={faPencil}/>
                            </button>}

                        </ButtonGroup>
                    }

                    dataTableArray.push(row);
                }

                setDataTableData(dataTableArray);

                dispatch(disableLoading());
            }
        });
    }

    return (<>
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-header bg-primary">
                        <div className="row">
                            <div className="col-6">
                                <h4 className="card-title text-white"
                                    style={{margin: "6px 0px 0px 0px"}}>{t("Groups")}</h4>
                            </div>
                            <div className="col text-end" style={{padding: "0px 0px 0px 0px"}}>
                                <ButtonGroup>
                                    <button type="button" className="btn btn-primary btn-icon-square-sm"
                                            onClick={() => fetchGroups()}>
                                        <FontAwesomeIcon icon={faRotate}/>
                                    </button>
                                    {(currentUser && currentGroup.is_admin) &&
                                        <Button className="btn btn-primary" style={{padding: "0px 0px 0px 0px"}}
                                                onClick={() => {
                                                    setSelectedGroup(null);
                                                    setAddModalShow(true)
                                                }}>
                                            <FontAwesomeIcon icon={faAdd}/> {t("Add group")}
                                        </Button>}
                                </ButtonGroup>
                            </div>
                        </div>

                    </div>

                    <div className="card-body">
                        <div className="table-responsive">
                            <DataTable columns={columns} data={dataTableData} pagination/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <AddGroupModal
            show={addModalShow}
            currentGroup={props.currentGroup}
            hideFn={() => setAddModalShow(false)}
            loadFn={() => fetchGroups()}
            selectedGroup={selectedGroup}
        />

    </>);
}