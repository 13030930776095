import './PedestrianStatistics.css';
import React, {useEffect, useState} from 'react';
import Moment from "react-moment";
import {ButtonGroup} from "@mui/material";
import {Button, Collapse} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus, faRotate} from "@fortawesome/free-solid-svg-icons";
import StatisticsCard from "../StatisticsCard/StatisticsCard";
import ReactApexChart from "react-apexcharts";
import GetPedestriansQuery from "../../queries/Statistics/GetPedestrians";
import ApexCharts from "apexcharts";
import {useTranslation} from "react-i18next";


export default function PedestrianStatistics(props) {
    const {t} = useTranslation();

    const [isPedestrianCardOpen, setIsPedestrianCardOpen] = useState(props.opened);

    const [isPedestrianDataLoaded, setIsPedestrianDataLoaded] = useState(false);

    const [pedestrianBoxesSettings, setPedestrianBoxesSettings] = useState({
        'crossings_a_b': {'selected': true},
        'crossings_b_a': {'selected': true},
        'jaywalking_a_b': {'selected': false},
        'jaywalking_b_a': {'selected': false}
    });

    const [pedestrianStatistics, setPedestrianStatistics] = useState({
        'crossings_a_b': 0,
        'crossings_b_a': 0,
        'jaywalking_a_b': 0,
        'jaywalking_b_a': 0,
        'crossings_a_b_data': [],
        'crossings_b_a_data': [],
        'jaywalking_a_b_data': [],
        'jaywalking_b_a_data': [],
    });

    const [pedestrianChartOptions] = useState({
        chart: {
            id: 'pedestrian-line-chart', type: 'area', stacked: false, height: 350, zoom: {
                type: 'x', enabled: true, autoScaleYaxis: false
            }, toolbar: {
                autoSelected: 'zoom'
            }
        }, stroke: {
            curve: 'straight',
        }, dataLabels: {
            enabled: false
        }, markers: {
            size: 0,
        },

        fill: {
            type: 'gradient', gradient: {
                shadeIntensity: 1, inverseColors: false, opacityFrom: 0.5, opacityTo: 0, stops: [0, 90, 100]
            },
        }, yaxis: {
            labels: {
                formatter: function (val) {
                    return (val).toFixed(0);
                },
            }, title: {
                text: 'Count'
            },
        }, xaxis: {
            type: 'datetime',
        }, tooltip: {
            shared: false, y: {
                formatter: function (val) {
                    return (val).toFixed(0)
                }
            }
        }
    });

    const [pedestrianChartSeries, setPedestrianChartSeries] = useState([]);
    const redrawPedestrianChart = () => {
        let pedestrianChartSeriesHolder = [];


        Object.keys(pedestrianBoxesSettings).forEach(function (key) {

            if (pedestrianBoxesSettings[key].selected) {
                // SHOW
                pedestrianChartSeriesHolder.push({
                    name: key, data: pedestrianStatistics[key + "_data"]
                })

            } else {
                pedestrianChartSeriesHolder.map((item, ix) => {
                    if (item.name === key) {
                        pedestrianChartSeriesHolder.splice(ix, 1);
                    }
                });
            }
        });

        let chart = ApexCharts.getChartByID("pedestrian-line-chart");


        chart.updateSeries(pedestrianChartSeriesHolder).then(() => {
            setPedestrianChartSeries(() => pedestrianChartSeriesHolder);
        });
    }

    const pedestrianBoxOnClick = (objectKey) => {
        setPedestrianBoxesSettings(prev => {
            return ({...prev, [objectKey]: {'selected': !prev[objectKey].selected}});
        })
    }
    const getPedestrianStatistics = () => {
        let startDateISO = props.startDate.toISOString();
        let endDateISO = props.endDate.toISOString();
        setPedestrianChartSeries([]);
        setIsPedestrianDataLoaded(false);

        GetPedestriansQuery(props.deviceId, startDateISO, endDateISO).then((result) => {
            setPedestrianStatistics((prev) => {

                return ({
                    ...prev,
                    'crossings_a_b': result.data.data.crossings_a_b,
                    'crossings_b_a': result.data.data.crossings_b_a,
                    'jaywalking_a_b': result.data.data.jaywalking_a_b,
                    'jaywalking_b_a': result.data.data.jaywalking_b_a,
                    'crossings_a_b_data': result.data.data.crossings_a_b_data,
                    'crossings_b_a_data': result.data.data.crossings_b_a_data,
                    'jaywalking_a_b_data': result.data.data.jaywalking_a_b_data,
                    'jaywalking_b_a_data': result.data.data.jaywalking_b_a_data,
                });
            });


            setIsPedestrianDataLoaded(true);


        });
    }


    useEffect(() => {
        getPedestrianStatistics();
    }, [props.startDate, props.endDate]);

    useEffect(() => {
        if (isPedestrianDataLoaded) {
            setPedestrianChartSeries([]);
            redrawPedestrianChart();
        }
    }, [isPedestrianDataLoaded]);

    useEffect(() => {
        setPedestrianChartSeries(() => {
            if (isPedestrianDataLoaded) {
                redrawPedestrianChart();
            }
            return ([]);
        });

    }, [pedestrianBoxesSettings]);

    return (<>
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-header bg-primary">
                        <div className="row">
                            <div className="col-6">
                                <h4 className="card-title text-white"
                                    style={{margin: "6px 0px 0px 0px"}}>
                                    {t("Pedestrian data")}, {t("Period: ")}
                                    <Moment format="DD.MM.yyyy">{props.startDate.toISOString()}</Moment>
                                    &nbsp;-&nbsp;
                                    <Moment format="DD.MM.yyyy">{props.endDate.toISOString()}</Moment>
                                </h4>
                            </div>
                            <div className="col text-end" style={{padding: "0px 0px 0px 0px"}}>
                                <ButtonGroup>
                                    <Button
                                        className="btn btn-primary"
                                        onClick={() => setIsPedestrianCardOpen(!isPedestrianCardOpen)}
                                        aria-controls="collapsable-pedestrian-card"
                                        aria-expanded={isPedestrianCardOpen}
                                    >
                                        {isPedestrianCardOpen && <FontAwesomeIcon icon={faMinus}/>}
                                        {!isPedestrianCardOpen && <FontAwesomeIcon icon={faPlus}/>}
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </div>

                    </div>
                    <Collapse in={isPedestrianCardOpen}>
                        <div id="collapsable-pedestrian-card">
                            {isPedestrianDataLoaded && <div className="card-body">

                                <div className="row text-center">
                                    <div className="col-3"
                                         onClick={() => pedestrianBoxOnClick('crossings_a_b')}>
                                        <StatisticsCard title={t("Crossings A->B")}
                                                        number={pedestrianStatistics.crossings_a_b}
                                                        settings={pedestrianBoxesSettings.crossings_a_b}/>

                                    </div>
                                    <div className="col-3"
                                         onClick={() => pedestrianBoxOnClick('crossings_b_a')}>
                                        <StatisticsCard title={t("Crossings B->A")}
                                                        number={pedestrianStatistics.crossings_b_a}
                                                        id="crossings_b_a"
                                                        settings={pedestrianBoxesSettings.crossings_b_a}/>

                                    </div>
                                    <div className="col-3"
                                         onClick={() => pedestrianBoxOnClick('jaywalking_a_b')}>
                                        <StatisticsCard title={t("Jaywalking A->B")}
                                                        number={pedestrianStatistics.jaywalking_a_b}
                                                        id="jaywalking_a_b"
                                                        settings={pedestrianBoxesSettings.jaywalking_a_b}/>

                                    </div>
                                    <div className="col-3"
                                         onClick={() => pedestrianBoxOnClick('jaywalking_b_a')}>
                                        <StatisticsCard title={t("Jaywalking B->A")}
                                                        number={pedestrianStatistics.jaywalking_b_a}
                                                        id="jaywalking_b_a"
                                                        settings={pedestrianBoxesSettings.jaywalking_b_a}/>

                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <ReactApexChart options={pedestrianChartOptions}
                                                        series={pedestrianChartSeries} type="area"
                                                        height={350}/>
                                    </div>
                                </div>

                            </div>}

                            {!isPedestrianDataLoaded && <>
                                <div className="card-body" style={{height: "200px"}}>

                                    <div className="row text-center">
                                        <div className="col-12">
                                            <FontAwesomeIcon icon={faRotate} size="10x" className="fa-spin"
                                                             style={{color: "#4d79f6"}}/>
                                        </div>
                                    </div>
                                </div>
                            </>}
                        </div>
                    </Collapse>
                </div>
            </div>
        </div>
    </>)

}