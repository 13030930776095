import React, {useEffect, useState} from 'react';
import '../Dashboard/DeployedDevices/DeployedDevices.css';
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faRotate,
} from "@fortawesome/free-solid-svg-icons";
import {ButtonGroup} from "@mui/material";
import GetANPRResultsQuery from "../../queries/ANPR/GetANPRResults";


export default function ANPRData() {
    const {t} = useTranslation();
    const [ANPRData, setANPRData] = useState();

    useEffect(() => {
        fetchANPR();
    }, []);

    const fetchANPR = () => {
        GetANPRResultsQuery().then((response) => {
            if (response.status === 200) {
                let results = response.data.data;

                setANPRData(results);

                setTimeout(fetchANPR, 1000);
            }
        });
    }

    return (<>
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-header bg-primary">
                        <div className="row">
                            <div className="col-6">
                                <h4 className="card-title text-white"
                                    style={{margin: "6px 0px 0px 0px"}}>
                                    {t("ANPR Records")}
                                </h4>
                            </div>
                            <div className="col text-end"
                                 style={{padding: "0px 0px 0px 0px"}}>
                                <ButtonGroup>
                                    <button type="button"
                                            className="btn btn-primary btn-icon-square-sm"
                                            onClick={() => fetchANPR()}>
                                        <FontAwesomeIcon icon={faRotate}/>
                                    </button>
                                </ButtonGroup>
                            </div>
                        </div>
                    </div>

                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-responsive">
                                {ANPRData && <>
                                    {ANPRData.map((anprResult) => {
                                        return (<tr>
                                            <td width="50%">{anprResult.plate_nr}</td>
                                            <td width="50%">{anprResult.created_at}</td>
                                            <td>
                                                <img
                                                    height={200}
                                                    width={200}
                                                    src={`data:image/jpeg;base64,${anprResult.snapshot}`} alt="snapshot"/>
                                            </td>
                                        </tr>)
                                    })}
                                </>}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}