import React, {useEffect, useState} from 'react';
import Moment from "react-moment";
import {ButtonGroup} from "@mui/material";
import {Button, Collapse} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus, faRotate} from "@fortawesome/free-solid-svg-icons";
import ReactApexChart from "react-apexcharts";
import GetPedestriansQuery from "../../queries/Statistics/GetPedestrians";
import ApexCharts from "apexcharts";
import {useTranslation} from "react-i18next";
import StatisticsCard from "../../components/StatisticsCard/StatisticsCard";
import GetAdvancedStatistics from "../../queries/Nutitee/GetAdvancedStatistics";


export default function NutiteeAdvanced(props) {
    const {t} = useTranslation();

    const [isNutiteeAdvancedCardOpen, setIsNutiteeAdvancedCardOpen] = useState(props.opened);

    const [isNutiteeAdvancedDataLoaded, setIsNutiteeAdvancedDataLoaded] = useState(false);

    const getZoneIds = () => {
        if(props.spc[0] === 2 && props.spc[1] === 5) {
            return [5, 6]
        }
        if(props.spc[0] === 5 && props.spc[1] === 10) {
            return [7, 8]
        }
        if(props.spc[0] === 10 && props.spc[1] === 11) {
            return [4, 3]
        }

        if(props.spc[0] === 11 && props.spc[1] === 14) {
            return [1, 2]
        }
    }

    const [nutiteeAdvancedBoxesSettings, setNutiteeAdvancedBoxesSettings] = useState({
        'average_a': {'selected': true},
        'average_b': {'selected': true},
        'speedings_a': {'selected': true},
        'speedings_b': {'selected': true},
        'overspeedings_a': {'selected': true},
        'overspeedings_b': {'selected': true},
        'max_speed_a': {'selected': true},
        'max_speed_b': {'selected': true}
    });

    const [nutiteeAdvancedStatistics, setNutiteeAdvancedStatistics] = useState({
        'average_a': 0,
        'average_b': 0,
        'speedings_a': 0,
        'speedings_b': 0,
        'overspeedings_a': 0,
        'overspeedings_b': 0,
        'max_speed_a': 0,
        'max_speed_b': 0,
        'average_a_data': [],
        'average_b_data': [],
    });

    const [nutiteeAdvancedChartOptions] = useState({
        chart: {
            id: 'pedestrian-line-chart' + props.spc[0]+ props.spc[1], type: 'area', stacked: false, height: 350, zoom: {
                type: 'x', enabled: true, autoScaleYaxis: false
            }, toolbar: {
                autoSelected: 'zoom'
            }
        }, stroke: {
            curve: 'straight',
        }, dataLabels: {
            enabled: false
        }, markers: {
            size: 0,
        },

        fill: {
            type: 'gradient', gradient: {
                shadeIntensity: 1, inverseColors: false, opacityFrom: 0.5, opacityTo: 0, stops: [0, 90, 100]
            },
        }, yaxis: {
            labels: {
                formatter: function (val) {
                    return (val).toFixed(0);
                },
            }, title: {
                text: 'Count'
            },
        }, xaxis: {
            type: 'datetime',
        }, tooltip: {
            shared: false, y: {
                formatter: function (val) {
                    return (val).toFixed(0)
                }
            }
        }
    });

    const [nutiteeAdvancedChartSeries, setNutiteeAdvancedChartSeries] = useState([]);
    const redrawNutiteeAdvancedChart = () => {
        let nutiteeAdvancedChartSeriesHolder = [];


        Object.keys(nutiteeAdvancedBoxesSettings).forEach(function (key) {

            if (nutiteeAdvancedBoxesSettings[key].selected) {
                // SHOW
                nutiteeAdvancedChartSeriesHolder.push({
                    name: key, data: nutiteeAdvancedStatistics[key + "_data"]
                })

            } else {
                nutiteeAdvancedChartSeriesHolder.map((item, ix) => {
                    if (item.name === key) {
                        nutiteeAdvancedStatistics.splice(ix, 1);
                    }
                });
            }
        });

        let chart = ApexCharts.getChartByID("pedestrian-line-chart" + props.spc[0]+ props.spc[1]);


        chart.updateSeries(nutiteeAdvancedChartSeriesHolder).then(() => {
            setNutiteeAdvancedChartSeries(() => nutiteeAdvancedChartSeriesHolder);
        });
    }


    useEffect(() => {
        if (isNutiteeAdvancedDataLoaded) {
            setNutiteeAdvancedChartSeries([]);
            redrawNutiteeAdvancedChart();
        }
    }, [isNutiteeAdvancedDataLoaded]);

    useEffect(() => {
        setNutiteeAdvancedChartSeries(() => {
            if (isNutiteeAdvancedDataLoaded) {
                redrawNutiteeAdvancedChart();
            }
            return ([]);
        });

    }, [nutiteeAdvancedBoxesSettings]);

    useEffect(() => {
        const abortController = new AbortController();
        const signal = abortController.signal;

        getZonesSettings(signal);

        return () => {
            //abortController.abort()
        }
    }, [props.startDate, props.endDate]);

    const getZonesSettings = (signal) => {
        let startDateISO = props.startDate.toISOString();
        let endDateISO = props.endDate.toISOString();
        setNutiteeAdvancedChartSeries([]);
        setIsNutiteeAdvancedDataLoaded(false);

        GetAdvancedStatistics(signal, getZoneIds()[0],getZoneIds()[1], startDateISO, endDateISO).then((result) => {
            setNutiteeAdvancedStatistics((prev) => {

                return ({
                    ...prev,
                    'average_a': result.data.data.average_a,
                    'average_b': result.data.data.average_b,
                    'speedings_a': result.data.data.speedings_a,
                    'speedings_b': result.data.data.speedings_b,
                    'overspeedings_a':result.data.data.overspeedings_a,
                    'overspeedings_b': result.data.data.overspeedings_b,
                    'max_speed_a': result.data.data.max_speed_a,
                    'max_speed_b': result.data.data.max_speed_b,
                    'average_a_data': result.data.data.average_a_data,
                    'average_b_data': result.data.data.average_b_data,
                });
            });


            setIsNutiteeAdvancedDataLoaded(true);
        });
    }

    return (<>
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-header bg-primary">
                        <div className="row">
                            <div className="col-6">
                                <h4 className="card-title text-white"
                                    style={{margin: "6px 0px 0px 0px"}}>
                                    {t("SPC "+props.spc[0]+"<->"+props.spc[1])}, {t("Period: ")}
                                    <Moment format="DD.MM.yyyy">{props.startDate.toISOString()}</Moment>
                                    &nbsp;-&nbsp;
                                    <Moment format="DD.MM.yyyy">{props.endDate.toISOString()}</Moment>
                                </h4>
                            </div>
                            <div className="col text-end" style={{padding: "0px 0px 0px 0px"}}>
                                <ButtonGroup>
                                    <Button
                                        className="btn btn-primary"
                                        onClick={() => setIsNutiteeAdvancedCardOpen(!isNutiteeAdvancedCardOpen)}
                                        aria-controls="collapsable-pedestrian-card"
                                        aria-expanded={isNutiteeAdvancedCardOpen}
                                    >
                                        {isNutiteeAdvancedCardOpen && <FontAwesomeIcon icon={faMinus}/>}
                                        {!isNutiteeAdvancedCardOpen && <FontAwesomeIcon icon={faPlus}/>}
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </div>

                    </div>
                    <Collapse in={isNutiteeAdvancedCardOpen}>
                        <div id="collapsable-pedestrian-card">
                            {isNutiteeAdvancedDataLoaded && <div className="card-body">

                                <div className="row text-center">
                                    <div className="col-3">
                                        <StatisticsCard title={t("Average speed "+props.spc[0]+"->"+props.spc[1])}
                                                        number={nutiteeAdvancedStatistics.average_a + " km/h"}
                                                        settings={nutiteeAdvancedBoxesSettings.average_a}/>

                                    </div>
                                    <div className="col-3">
                                        <StatisticsCard title={t("Speedings "+props.spc[0]+"->"+props.spc[1])}
                                                        number={nutiteeAdvancedStatistics.speedings_a + "%"}
                                                        settings={nutiteeAdvancedBoxesSettings.speedings_a}/>

                                    </div>
                                    <div className="col-3">
                                        <StatisticsCard title={t("Over 65 km/h "+props.spc[0]+"->"+props.spc[1])}
                                                        number={nutiteeAdvancedStatistics.overspeedings_a + "%"}
                                                        settings={nutiteeAdvancedBoxesSettings.overspeedings_a}/>

                                    </div>
                                    <div className="col-3">
                                        <StatisticsCard title={t("Max speed "+props.spc[0]+"->"+props.spc[1])}
                                                        number={nutiteeAdvancedStatistics.max_speed_a + " km/h"}
                                                        settings={nutiteeAdvancedBoxesSettings.max_speed_a}/>
                                    </div>


                                    <div className="col-3">
                                        <StatisticsCard title={t("Average speed "+props.spc[1]+"->"+props.spc[0])}
                                                        number={nutiteeAdvancedStatistics.average_b + " km/h"}
                                                        settings={nutiteeAdvancedBoxesSettings.average_b}/>

                                    </div>
                                    <div className="col-3">
                                        <StatisticsCard title={t("Speedings "+props.spc[1]+"->"+props.spc[0])}
                                                        number={nutiteeAdvancedStatistics.speedings_b + "%"}
                                                        settings={nutiteeAdvancedBoxesSettings.speedings_b}/>

                                    </div>
                                    <div className="col-3">
                                        <StatisticsCard title={t("Over 65 km/h "+props.spc[1]+"->"+props.spc[0])}
                                                        number={nutiteeAdvancedStatistics.overspeedings_b + "%"}
                                                        settings={nutiteeAdvancedBoxesSettings.overspeedings_b}/>

                                    </div>
                                    <div className="col-3">
                                        <StatisticsCard title={t("Max speed "+props.spc[1]+"->"+props.spc[0])}
                                                        number={nutiteeAdvancedStatistics.max_speed_b + " km/h"}
                                                        settings={nutiteeAdvancedBoxesSettings.max_speed_b}/>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <ReactApexChart options={nutiteeAdvancedChartOptions}
                                                        series={nutiteeAdvancedChartSeries} type="area"
                                                        height={350}/>
                                    </div>
                                </div>

                            </div>}

                            {!isNutiteeAdvancedDataLoaded && <>
                                <div className="card-body" style={{height: "200px"}}>

                                    <div className="row text-center">
                                        <div className="col-12">
                                            <FontAwesomeIcon icon={faRotate} size="10x" className="fa-spin"
                                                             style={{color: "#4d79f6"}}/>
                                        </div>
                                    </div>
                                </div>
                            </>}
                        </div>
                    </Collapse>
                </div>
            </div>
        </div>
    </>)

}