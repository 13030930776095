import {CONFIG} from "../../config";
import axios from "axios";


async function LoginQuery(email, password) {
    const data = {
        'email': email,
        'password': password
    }

    const location = CONFIG.API_URL + "user/login"
    const settings = {
        headers: {
            'Content-Type': 'application/json',
        }
    };
    try {
        return await axios.post(location, data, settings);
    } catch (e) {
        return e;
    }
}

export default LoginQuery;