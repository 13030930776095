import {CONFIG} from "../../config";
import axios from "axios";

async function AddDeviceQuery(name, airly_id, speed_trigger, modification, radars, coordinates) {
    const data = {
        'name': name,
        'airly_id': airly_id,
        'radars': radars,
        'speed_trigger': speed_trigger,
        'modification': modification,
        'coordinates': coordinates
    }

    const location = CONFIG.API_URL + "device/add";
    const settings = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('bearer-token')}`,
        }
    };
    try {
        return await axios.post(location, data, settings);
    } catch (e) {
        return e;
    }
}

export default AddDeviceQuery;