import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {useTranslation} from "react-i18next";
import Form from 'react-bootstrap/Form';
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {disableLoading, enableLoading} from "../../../features/loading/loadingSlice";
import withReactContent from "sweetalert2-react-content";
import swal from 'sweetalert2';
import GetDevicesQuery from "../../../queries/Devices/GetDevices";
import MultiSelectDropdown from "../../MultiSelectDropdown/MultiSelectDropdown";
import AddGroupQuery from "../../../queries/Groups/AddGroup";
import EditGroupQuery from "../../../queries/Groups/EditGroup";

export default function AddGroupModal(props) {
    const {t} = useTranslation();
    withReactContent(swal);
    const dispatch = useDispatch();
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [isAdmin, setIsAdmin] = useState("");
    const [devicesMultiselect, setDevicesMultiselect] = useState([]);
    const [selectedDevices, setSelectedDevices] = useState([]);
    const [selectedDevicesData, setSelectedDevicesData] = useState([]);
    const [currentGroup] = useState(props.currentGroup);

    useEffect(() => {
        if (props.selectedGroup) {
            setName(props.selectedGroup.name);
            setDescription(props.selectedGroup.description);
            setIsAdmin(props.selectedGroup.is_admin);

            let sm = [];

            for (const [, value] of Object.entries(props.selectedGroup.devices)) {
                sm.push(value.id);
            }

            setSelectedDevicesData(sm);
        }
    }, props.selectedGroup);

    useEffect(() => {
        fetchDevices();
    }, []);

    const fetchDevices = () => {
        dispatch(enableLoading());
        GetDevicesQuery(currentGroup.id).then((response) => {
            if (response.status === 200) {
                let devices = response.data.data;

                let dm = [];

                for (const [, value] of Object.entries(devices)) {
                    dm.push({'id': value.id, 'value': value.name});
                }

                setDevicesMultiselect(dm);

                dispatch(disableLoading());
            }
        })
    }

    const handleNameChange = (e) => {
        setName(e.target.value);
    }

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    }

    const handleIsAdminChange = (e) => {
        setIsAdmin(e.target.value);
    }

    const cleanAndCloseModal = () => {
        setName("");
        setDescription("");
        setIsAdmin("");

        props.loadFn();
        props.hideFn();
    }

    const saveGroup = () => {
        dispatch(enableLoading());

        if (props.selectedGroup) {
            EditGroupQuery(props.selectedGroup.id, name, description, currentGroup.id, isAdmin, selectedDevices).then(() => {
                dispatch(disableLoading());
                cleanAndCloseModal();
            });
        } else {
            AddGroupQuery(name, description, currentGroup.id, isAdmin, selectedDevices).then(() => {
                dispatch(disableLoading());
                cleanAndCloseModal();
            });
        }
    }

    return (<Modal {...props} onHide={cleanAndCloseModal}>
        <Modal.Header>
            {props.selectedGroup && <Modal.Title>{props.selectedGroup.name}</Modal.Title>}
            {!props.selectedGroup && <Modal.Title>{t("Add group")}</Modal.Title>}
        </Modal.Header>
        <Modal.Body>
            <Form>
                <Form.Group className="mb-3" controlId="nameCtl">
                    <Form.Label>{t("Name")}</Form.Label>
                    <Form.Control type="name" placeholder={t("Enter group name")} value={name}
                                  onChange={handleNameChange}/>
                </Form.Group>

                {devicesMultiselect && <MultiSelectDropdown name="Select devices(s)" options={devicesMultiselect}
                                                            setSelected={setSelectedDevices}
                                                            selectedOptions={selectedDevicesData}/>}

                <Form.Group className="mb-3" controlId="isAdminCtl">
                    <Form.Label>{t("Is administrator")}</Form.Label><br/>
                    <Form.Select value={isAdmin} onChange={handleIsAdminChange}>
                        <option value="0">{t("No")}</option>
                        <option value="1">{t("Yes")}</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3" controlId="descriptionCtl">
                    <Form.Label>{t("Description")}</Form.Label>
                    <Form.Control as="textarea" placeholder={t("Enter description")} value={description}
                                  onChange={handleDescriptionChange}/>
                </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={cleanAndCloseModal}>
                {t("Close")}
            </Button>
            <Button variant="primary" onClick={() => {
                saveGroup();
            }}>
                {t("Save")}
            </Button>
        </Modal.Footer>
    </Modal>);
}