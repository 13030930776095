import i18n from "i18next";
import {initReactI18next} from "react-i18next";

import translationEN from './locales/en/translation.json';
import translationEE from './locales/ee/translation.json';
import translationCR from './locales/cr/translation.json';

// the translations
const resources = {
    en: {
        translation: translationEN
    }, ee: {
        translation: translationEE
    }, cr: {
        translation: translationCR
    }
};

const DETECTION_OPTIONS = {
    order: ['localStorage', 'navigator'], caches: ['localStorage']
};

i18n
    //.use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        detection: DETECTION_OPTIONS, resources, fallbackLng: "en", keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    }).then();

export default i18n;