import * as L from 'leaflet';
import Line from './Line';

const max = (arr) => arr.reduce((_max, value) => {
    if(null == value)
        return _max;

    return Math.max(_max, value)
}, Number.MIN_SAFE_INTEGER);
const min = (arr) => arr.reduce((_min, value) => {
    if(null == value)
        return _min;

    return Math.min(_min, value)
}, Number.MAX_SAFE_INTEGER);

function isNumber(val) {
    return typeof val === 'number';
}

function isInvalid(num) {
    return !isNumber(num) && !num;
}

function isValid(num) {
    return !isInvalid(num);
}

function isValidLatLngArray(arr) {
    return arr.filter(isValid).length === arr.length;
}

export default class Linemap extends L.Layer {
    zoomRadius = {
        18: 13,
        17: 12,
        16: 7,
        15: 6
    };

    constructor(options) {
        super(options);

        this.options = L.Util.setOptions(this, options);
    }

    get _heatmap() {
        if(!this.__heatmap) {
            this.__el = document.createElement('canvas');
            this.__heatmap = new Line(this.__el);
        }

        return this.__heatmap;
    }

    get _el() {
        if(!this.__el) {
            this.__el = document.createElement('canvas');
            this.__heatmap = new Line(this.__el);
        }

        return this.__el;
    }

    getPane() {
        let _a;
        return (_a = super.getPane()) !== null && _a !== void 0 ? _a : this._map.getPanes().overlayPane;
    }

    drawTooltip(mouse) {
        if(!this._tooltip) {
            this._tooltip = document.createElement("div");
            this._tooltip.className = "heatmap-tooltip";

            document.body.appendChild(this._tooltip);
        }
        const doc = document.documentElement;
        this._tooltip.style.left = (mouse.origX + doc.scrollLeft) - 100 + 'px';
        this._tooltip.style.top = (mouse.origY + doc.scrollTop) - 70 + 'px';

        let point = this.checkPoint(mouse);
        if((mouse.x < 0 || mouse.x > mouse.width) || (mouse.y < 0 || mouse.y > mouse.height)) {
            this._tooltip.style.visibility = "hidden";

            return;
        }

        if(point) {
            console.log(point)
            this._tooltip.innerHTML = `
                <div>
                    Kiirus A->B: ${point.a}
                    </br>
                    Kiirus B->A: ${point.b}
                </div>
            `;
            this._tooltip.style.visibility = "visible";
        } else {
            this._tooltip.style.visibility = "hidden";
        }
    }

    checkPoint(mouse) {
        for(let input of this.options.lines) {
            for(let i = 0; i < input.points.length - 1; i++) {
                let point1 = this._map.latLngToContainerPoint([input.points[i][0], input.points[i][1]]);
                let point2 = this._map.latLngToContainerPoint([input.points[i + 1][0], input.points[i + 1][1]]);

                let line = {
                    x1: point1.x,
                    y1: point1.y,
                    x2: point2.x,
                    y2: point2.y
                }

                if(this.mouseOnLine(line, mouse.x, mouse.y))
                    return { a: input.zoneA, b: input.zoneB };
            }
        }

        return null;
    }

    dist(x1, y1, x2, y2) {
        return Math.sqrt((y2 - y1) * (y2 - y1) + (x2 - x1) * (x2 - x1));
    }

    mouseOnLine(line, x, y) {
        let distAC = this.dist(line.x1, line.y1, x, y);
        let distCB = this.dist(x, y, line.x2, line.y2);
        let distAB = this.dist(line.x1, line.y1, line.x2, line.y2);
        let comp = distAC + distCB;

        return (comp - distAB) < 5;
    };

    onAdd(map) {
        const canAnimate = map.options.zoomAnimation && L.Browser.any3d;
        const zoomClass = `leaflet-zoom-${canAnimate ? 'animated' : 'hide'}`;
        const mapSize = map.getSize();

        this.zoom = this._map._zoom;

        this._el.className = zoomClass;
        this._el.style.transformOrigin = '50% 50%';
        this._el.width = mapSize.x;
        this._el.height = mapSize.y;

        document.addEventListener("mousemove", (event) => {
            const rect = this._el.getBoundingClientRect();
            const mouse = {
                x: event.clientX - rect.left,
                y: event.clientY - rect.top,
                origX: event.clientX,
                origY: event.clientY,
                width: rect.width,
                height: rect.height
            };

            this.drawTooltip(mouse);
        }, true);

        this.updateHeat(this.getHeatOptions());
        this._heatmap.resize();

        this.getPane().appendChild(this._el);
        this.reset();

        if(this.options.fitBoundsOnLoad)
            this.fitBounds();

        return this;
    }

    onRemove() {
        const pane = this.getPane();
        if(pane.contains(this._el))
            pane.removeChild(this._el);

        return this;
    }

    getEvents() {
        return {
            viewreset: this.reset,
            moveend: this.reset,
            zoomanim: this._animateZoom
        }
    }

    _animateZoom(e) {
        const _e = e;
        const scale = this._map.getZoomScale(_e.zoom);
        const offset = this._map
            .latLngToLayerPoint(_e.center)
            .subtract(this._map.containerPointToLayerPoint(this._map.getSize().divideBy(2)))
            .multiplyBy(-scale)
            .subtract(this._map.layerPointToContainerPoint([0, 0]));

        L.DomUtil.setTransform(this._el, offset, scale);
    }

    fitBounds() {
        const { lines, longitudeExtractor, latitudeExtractor } = this.options;
        let lngs = [];
        let lats = [];
        for(let line of lines) {
            const lng = line.points.map(longitudeExtractor);
            const lat = line.points.map(latitudeExtractor);
            lngs.push(...lng);
            lats.push(...lat);
        }
        const ne = { lng: max(lngs), lat: max(lats) };
        const sw = { lng: min(lngs), lat: min(lats) };

        this._map.fitBounds(L.latLngBounds(L.latLng(sw), L.latLng(ne)));
    }

    resize() {
        if(!this._map)
            return;

        const size = this._map.getSize();
        if(size.x !== this._el.width || size.y !== this._el.height) {
            this._el.width = size.x;
            this._el.height = size.y;
            this._heatmap.resize();
        }
    }

    getMinOpacity() {
        let _a;
        return (_a = this.options.minOpacity) !== null && _a !== void 0 ? _a : 0.5;
    }

    getHeatOptions() {
        return {
            gradient: this.options.gradient
        }
    }

    updateHeat(options) {
        this.updateHeatmapRadius(options.radius, options.blur);
        this.updateHeatmapGradient(options.gradient);
    }

    updateHeatmapRadius(radius, blur) {
        if(isNumber(radius))
            this._heatmap.radius(radius, blur);
    }

    updateHeatmapGradient(gradient) {
        if(gradient)
            this._heatmap.gradient(gradient);
    }

    redraw() {
        if(!this._map)
            return;

        this.updateHeatmapRadius(this.zoomRadius[this._map._zoom] ?? 4, this.options.blur);

        const getLat = this.options.latitudeExtractor;
        const getLng = this.options.longitudeExtractor;

        this._heatmap.clear();

        let data = []
        for(let input of this.options.lines) {
            let line = [];
            for(let p of input.points) {
                const latlng = [getLat(p), getLng(p)];
                const point = this._map.latLngToContainerPoint(latlng);

                line.push([point.x, point.y, input.intensity]);
            }

            data.push(line);
        }

        this._heatmap.data(data);

        try {
            this._heatmap.draw(this.getMinOpacity());
        } catch (DOMException) {}

        this._frame = null;

        this._el.style.opacity = this.getMinOpacity().toString();
    }

    reset() {
        if(!this._map)
            return;

        L.DomUtil.setPosition(this._el, this._map.containerPointToLayerPoint([0, 0]));

        this.resize();
        if(this._heatmap && !this._frame) {
            this._frame = L.Util.requestAnimFrame(this.redraw, this);
        }
        this.redraw();
    }
};