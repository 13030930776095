import React, {useEffect, useState} from 'react';
import './ANPR.css';
import {useTranslation} from "react-i18next";
import ANPRData from "../../components/ANPR/ANPRData";

export default function ANPR() {
    const {t} = useTranslation();
    const [currentUser, setCurrentUser] = useState();
    const [currentGroup, setCurrentGroup] = useState();

    useEffect(() => {
        setCurrentUser(JSON.parse(localStorage.getItem("me")));
        setCurrentGroup(JSON.parse(localStorage.getItem("current_group")));
    }, []);

    return (<>
        <div className="row">
            <div className="col-sm-12">
                <div className="page-title-box">
                    <h4 className="page-title">{t("ANPR Debug data")}</h4>
                </div>
            </div>
        </div>
        {(currentUser && currentGroup) && <>
            <ANPRData></ANPRData>
        </>}
    </>);
}