import React, {useEffect, useState} from 'react';
import './DeviceSettings.css';
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import {useNavigate, useParams} from 'react-router-dom';
import {Container} from "react-bootstrap";
import {Accordion} from "react-bootstrap";
import GetDeviceCoresQuery from '../../queries/DeviceHeartbeat/GetDeviceCores';
import InteractiveSettings from "../../components/InteractiveSettings/InteractiveSettings";
import {useDispatch} from "react-redux";
import {disableLoading, enableLoading} from "../../features/loading/loadingSlice";

export default function DeviceSettings() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const [cores, setCores] = useState([]);


    const dispatch = useDispatch();

    const deviceId = params.deviceID;

    useEffect(() => {
        fetchCores();
    }, []);

    const fetchCores = () => {
        dispatch(enableLoading());
        GetDeviceCoresQuery(deviceId).then((response) => {
            if (response.status === 200) {
                let data = response.data.data;
                setCores(data);
            }

            dispatch(disableLoading());
        })
    }

    return (<>
        <div className="row">
            <div className="col-sm-12">
                <div className="page-title-box">
                    <h4 className="page-title">
                        <a className='back-text' style={{textDecoration: "none"}} href="#"
                           onClick={() => navigate(-1)}>
                            <FontAwesomeIcon className='back-button' icon={faChevronLeft}/>
                            &nbsp; {t("Dashboard")}
                        </a>
                    </h4>
                </div>
            </div>
        </div>
        <>

            <Container fluid>
                <Accordion className='custom-accordion' defaultActiveKey="0">
                    {cores.map((core, index) => (<Accordion.Item eventKey={index}>
                        <Accordion.Header>{core}</Accordion.Header>
                        <Accordion.Body fluid>
                            <InteractiveSettings deviceId={deviceId} core={core}/>
                        </Accordion.Body>
                    </Accordion.Item>))}
                </Accordion>
            </Container>
        </>
    </>);
}