import React, {useEffect, useState, useRef} from "react";
import {useTranslation} from "react-i18next";
import "./Login.css";
import {useNavigate} from "react-router-dom";
import LoginQuery from "../../queries/User/Login";
import {Blocks} from "react-loader-spinner";
import EditUserQuery from "../../queries/User/EditUser";
import withReactContent from "sweetalert2-react-content";
import swal from "sweetalert2";
import Button from "react-bootstrap/Button";
import {
    Modal, ModalBody, ModalHeader, Form, Row, Col, Container,
} from "react-bootstrap";
import {useSelector, useDispatch} from "react-redux";
import {showNutitee} from "../../features/nutitee/nutiteeSlice";
import Puu from "../../assets/icons/Puu.svg";
import Bird from "../../assets/icons/Bird.svg";

export default function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrormessage] = useState("");
    const [userData, setUserData] = useState();
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [newPassword, setNewPassword] = useState();
    const [verifyNewPassword, setVerifyNewPassword] = useState();
    const userAddMessage = withReactContent(swal);
    const [editIsLoading, setEditIsLoading] = useState(false);
    const dispatch = useDispatch();
    const nutitee = useSelector((state) => state.nutitee.value);
    const [width, setWidth] = useState();
    const ref = useRef(null);

    const handleModalClose = () => setShowModal(false);
    const handleModalShow = () => setShowModal(true);

    const handleNewPasswordChange = (e) => {
        setNewPassword(e.target.value);
    };
    const handleVerifyNewPasswordChange = (e) => {
        setVerifyNewPassword(e.target.value);
    };

    var loginPageElement;

    useEffect(() => {
        document.body.classList.add("account-body");
        document.body.classList.add("login-page");

        if (window.location.href.includes("nutitee")) {
            loginPageElement = document.querySelector(".login-page");
            loginPageElement.style.background = "linear-gradient(150deg, rgba(27, 153, 19, 1) 50%, rgba(255, 255, 255, 1) 50%)";
        }

        if (localStorage.getItem("bearer-token") && localStorage.getItem("me")) {
            loginPageElement.style.background = "";
            navigate("/");
        }
    }, []);

    useEffect(() => {
        setWidth(ref.current.clientWidth);

        function handleWindowResize() {
            setWidth(ref.current.clientWidth);
        }

        window.addEventListener("resize", handleWindowResize);
        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    const doLogin = async () => {
        setErrormessage("");
        setShowError(false);

        setIsLoading(true);
        if (email.trim().length < 2 || password.trim() < 2) {
            setShowError(true);
            setIsLoading(false);
            return;
        }

        let result = await LoginQuery(email, password);

        if (result.status !== 200) {
            setShowError(true);
            setErrormessage(result.response.data.message);
            setIsLoading(false);
            return;
        }

        let data = result.data.data;

        if (data.user.groups.length < 1) {
            setShowError(true);
            setErrormessage("User does not belong to any group!");
            setIsLoading(false);
            return;
        }

        localStorage.setItem("bearer-token", data.access_token);
        localStorage.setItem("me", JSON.stringify(data.user));
        localStorage.setItem("current_group", JSON.stringify(data.user.groups[0]));

        setIsLoading(false);

        setUserData(JSON.parse(localStorage.getItem("me")));

        if (data.user.init_password === 1) {
            handleModalShow();
        } else {
            loginPageElement = document.querySelector(".login-page");
            loginPageElement.style.background = "";
            navigate("/");
        }
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const savePassword = () => {
        setEditIsLoading(true);

        let errMessage;

        if (newPassword !== verifyNewPassword) {
            errMessage = "Passwords don't match";
            userAddMessage
                .fire({
                    title: <strong>{t("Error")}</strong>, html: <i>{t(errMessage)}</i>, icon: "error",
                })
                .then();
        } else if (!newPassword) {
            errMessage = "Password field is empty";
            userAddMessage
                .fire({
                    title: <strong>{t("Error")}</strong>, html: <i>{t(errMessage)}</i>, icon: "error",
                })
                .then();
        } else {
            let sg = [];
            for (const [, value] of Object.entries(userData.groups)) {
                sg.push(value.id);
            }

            EditUserQuery(userData.id, userData.email, userData.first_name, userData.last_name, password, newPassword, userData.description, sg).then((response) => {
                handleServerResponse(response);
            });
            return;
        }
        setEditIsLoading(false);
    };

    const handleServerResponse = (response) => {
        if (response.status !== 200 && response.status !== 201) {
            let message = response.response.data?.message;

            if (message === "Validation error") {
                let data = response.response.data.data;

                message = "";

                for (const [, value] of Object.entries(data)) {
                    message = message + value[0] + "<br/>";
                }
            }

            setEditIsLoading(false);

            userAddMessage
                .fire({
                    title: <strong>{t("Error")}</strong>, html: message, icon: "error",
                })
                .then();

            return;
        }

        setEditIsLoading(false);

        userAddMessage
            .fire({
                title: <strong>{t("Settings changed")}</strong>, icon: "success", showConfirmButton: false, timer: 2000,
            })
            .then(() => {
                handleModalClose();
                navigate("/");
            });
    };

    const loginForm = () => {
        return (<div className="auth-page">
            <div className="card auth-card shadow-lg">
                <div className="card-body">
                    <div className="px-3">
                        <div className="auth-logo-box">
                            <a href="/" className="logo logo-admin">
                                <img
                                    src="/images/logo-sm.png"
                                    height="94"
                                    alt="logo"
                                    className="auth-logo"
                                />
                            </a>
                        </div>
                        <div className="text-center auth-logo-text">
                            <h4 className="mt-0 mb-3 mt-5">Ready to manage?</h4>
                            <p className="text-muted mb-0">
                                Sign in to continue to the management Console
                            </p>
                            {showError > 0 && (<div
                                className="alert alert-outline-danger alert-danger-shadow mb-0 my-4"
                                role="alert"
                            >
                                {errorMessage}
                            </div>)}
                        </div>
                        <br/>
                        <div className="form-group">
                            <label htmlFor="username">E-mail</label>
                            <div className="input-group mb-3">
                  <span className="auth-form-icon">
                    <i className="dripicons-user"></i>
                  </span>
                                <input
                                    type="text"
                                    name="email"
                                    className="form-control"
                                    id="email"
                                    placeholder="Enter e-mail"
                                    value={email}
                                    autoFocus={true}
                                    onChange={handleEmailChange}
                                    onKeyDown={(e) => (e.key === "Enter" ? doLogin() : "")}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="userpassword">Password</label>
                            <div className="input-group mb-3">
                  <span className="auth-form-icon">
                    <i className="dripicons-lock"></i>
                  </span>
                                <input
                                    type="password"
                                    name="password"
                                    className="form-control"
                                    id="userpassword"
                                    placeholder="Enter password"
                                    autoComplete="on"
                                    value={password}
                                    onChange={handlePasswordChange}
                                    onKeyDown={(e) => (e.key === "Enter" ? doLogin() : "")}
                                />
                            </div>
                        </div>
                        <div className="form-group mb-0 row">
                            <div className="col-12 mt-2" style={{textAlign: "center"}}>
                                <Button
                                    className="log-in-button"
                                    onClick={() => doLogin()}
                                    disabled={isLoading}
                                >
                                    {!isLoading && (<>
                                        {"Log In "}
                                        <i className="fas fa-sign-in-alt ml-1"></i>
                                    </>)}

                                    <Blocks
                                        visible={isLoading}
                                        height="26"
                                        width="26"
                                        ariaLabel="blocks-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="blocks-wrapper"
                                    />
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    };

    const PolvaLoginForm = () => {
        return (<div className="auth-page">
            {width > 775 && <img className="tree-pic" src={Puu} alt="tree"></img>}
            {width > 775 && <img className="bird-pic" src={Bird} alt="bird"></img>}

            <div className="card auth-card shadow-none">
                <div
                    className="card-body"
                    style={{
                        background: "#ffffff", borderRadius: "20px", boxShadow: "8px 8px 10px #5c5c5c",
                    }}
                >
                    <div className="px-3">
                        <div className="auth-logo-box">
                            <a href="/" className="logo logo-admin">
                                <img
                                    src="/images/logo-sm.png"
                                    height="94"
                                    alt="logo"
                                    className="auth-logo"
                                />
                            </a>
                        </div>

                        <div className="text-center auth-logo-text">
                            <h4 className="mt-0 mb-3 mt-5">Põlva Nutitee</h4>
                            <p className="text-muted mb-0">
                                Haldusportaali kasutamiseks logige sisse
                            </p>
                            {showError > 0 && (<div
                                className="alert alert-outline-danger alert-danger-shadow mb-0 my-4"
                                role="alert"
                            >
                                {errorMessage}
                            </div>)}
                        </div>
                        <br/>
                        <div className="form-group">
                            <label htmlFor="username">E-mail</label>
                            <div className="input-group mb-3">
                  <span className="auth-form-icon">
                    <i className="dripicons-user"></i>
                  </span>
                                <input
                                    type="text"
                                    name="email"
                                    className="form-control"
                                    id="email"
                                    placeholder="Sisesta e-mail"
                                    value={email}
                                    autoFocus={true}
                                    onChange={handleEmailChange}
                                    onKeyDown={(e) => (e.key === "Enter" ? doLogin() : "")}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="userpassword">Parool</label>
                            <div className="input-group mb-3">
                  <span className="auth-form-icon">
                    <i className="dripicons-lock"></i>
                  </span>
                                <input
                                    type="password"
                                    name="password"
                                    className="form-control"
                                    id="userpassword"
                                    placeholder="Sisesta parool"
                                    autoComplete="on"
                                    value={password}
                                    onChange={handlePasswordChange}
                                    onKeyDown={(e) => (e.key === "Enter" ? doLogin() : "")}
                                />
                            </div>
                        </div>
                        <div className="form-group mb-0 row">
                            <div className="col-12 mt-2" style={{textAlign: "center"}}>
                                <Button
                                    className="log-in-button"
                                    onClick={() => doLogin()}
                                    disabled={isLoading}
                                >
                                    {!isLoading && (<>
                                        {"Logi sisse "}
                                        <i className="fas fa-sign-in-alt ml-1"></i>
                                    </>)}

                                    <Blocks
                                        visible={isLoading}
                                        height="26"
                                        width="26"
                                        ariaLabel="blocks-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="blocks-wrapper"
                                    />
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    };

    return (<>
        <Container ref={ref} className="login-color">

            <Row style={{width: "100%"}}>

                <Col className="form-col">
                    {nutitee ? <>{PolvaLoginForm()}</> : loginForm()}
                </Col>
            </Row>
        </Container>

        <Modal
            className="init-pw-change-modal"
            show={showModal}
            centered={true}
            backdrop="static"
            keyboard={false}
        >
            <ModalHeader>
                <Modal.Title>Choose new password</Modal.Title>
            </ModalHeader>
            <ModalBody>
                <Form.Group className="mb-3">
                    <Form.Control
                        type="password"
                        placeholder={t("New password")}
                        onChange={handleNewPasswordChange}
                    />
                    <Form.Control
                        type="password"
                        placeholder={t("Repeat new password")}
                        onChange={handleVerifyNewPasswordChange}
                        style={{marginTop: "10px"}}
                    />
                </Form.Group>
            </ModalBody>
            <Modal.Footer>
                <Button
                    variant="primary"
                    onClick={() => savePassword()}
                    disabled={editIsLoading}
                >
                    {!editIsLoading && <>{t("Save")}</>}
                    <Blocks
                        visible={editIsLoading}
                        height="26"
                        width="26"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                    />
                </Button>
            </Modal.Footer>
        </Modal>
    </>);
}
