import {CONFIG} from "../../config";
import axios from "axios";

async function AddUserQuery(email, first_name, last_name, password, description, groups) {
    const data = {
        'email': email,
        'first_name': first_name,
        'last_name': last_name,
        'password': password,
        'description': description,
        'groups': groups
    }

    const location = CONFIG.API_URL + "user/add";
    const settings = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('bearer-token')}`,
        }
    };
    try {
        return await axios.post(location, data, settings);
    } catch (e) {
        return e;
    }
}

export default AddUserQuery;