import React, {useEffect, useState} from 'react';
import './TopProfile.css';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';

export default function TopProfile() {
    const {t} = useTranslation();
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        setUserData(JSON.parse(localStorage.getItem("me")));
    }, []);

    const dropdownTitle = (<>
        <img
            src="/images/logo-sm.png"
            alt="profile-user"
            className="rounded-circle"
        />
        <span className="ml-1 nav-user-name hidden-sm">
                {userData && <> {userData.first_name} </>}
            <i className="mdi mdi-chevron-down"/>
            </span>
    </>);

    return (<>
        <Nav>
            <NavDropdown className='custom-dropdown' title={dropdownTitle}>

                <NavDropdown.Item as={Link} to="/profile">
                        <span>
                            <i className="dripicons-user text-muted mr-2"/> {t(" Profile")}
                        </span>
                </NavDropdown.Item>

                <NavDropdown.Item as={Link} to="/">
                    <i className="dripicons-gear text-muted mr-2"/> {t(" Settings")}
                </NavDropdown.Item>

                <NavDropdown.Item as={Link} to={"/logout"}>
                    <i className="dripicons-exit text-muted mr-2"/> {t(" Log out")}
                </NavDropdown.Item>
            </NavDropdown>
        </Nav>
    </>);
}