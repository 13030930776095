import React, {useEffect, useRef, useState} from 'react';
import './InteractiveSettings.css'
import {ButtonGroup} from "@mui/material";
import {Accordion, Button, Col, Collapse, Form, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Alert from 'react-bootstrap/Alert';
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import GetDeviceConfiguration from "../../queries/DeviceConfiguration/GetDeviceConfiguration";
import {disableLoading, enableLoading} from "../../features/loading/loadingSlice";
import InitializeConfigDownloadQuery from "../../queries/DeviceConfiguration/InitializeConfigDownload";
import {useDispatch} from "react-redux";
import {AxiosError} from "axios";
import moment from "moment-timezone";
import withReactContent from "sweetalert2-react-content";
import swal from "sweetalert2";
import SyncConfigQuery from "../../queries/DeviceConfiguration/SyncConfig";
import SaveConfigQuery from "../../queries/DeviceConfiguration/SaveConfig";

const InteractiveSettings = (props) => {
    let ctx = null;

    const imageMultipier = 1.1;

    const {t} = useTranslation();
    const [isCardOpen, setIsCardOpen] = useState(true);
    const [isNoData, setIsNoData] = useState(true);
    const [config, setConfig] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isNewConfigDownloadInitialized, setIsNewConfigDownloadInitialized] = useState(false);

    const [coreConfigFormData, setCoreConfigFormData] = useState()
    const [nanoConfigFormData, setNanoConfigFormData] = useState()
    const [image, setImage] = useState();

    const [configDate, setConfigDate] = useState();
    const [isOutOfSync, setIsOutOfSync] = useState(false);
    const [parsedConfigDate, setParsedConfigDate] = useState();

    const saveConfigConfirmSWAL = withReactContent(swal);
    const saveConfigSavedSWAL = withReactContent(swal);

    const canvas = useRef();

    // crosswalk scrollbar handlers
    const [isDragging, setDragging] = useState(false);
    const [dragStart, setDragStart] = useState(0);
    const [showCrossingLine, setShowCrossingLine] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(null);
    const [barPercentage, setBarPercentage] = useState(null);
    const [detectionSplitter, setDetectionSplitter] = useState(0);

    // polygon draw
    const [showDrawZone, setShowDrawZone] = useState(false);
    const [perimeter, setPerimeter] = useState([]);
    const [complete, setComplete] = useState(false);

    useEffect(() => {
        if (complete) {
            parseCoordinates();
        }
    }, [complete]);

    const handleMouseDown = (e) => {
        if (showCrossingLine) {
            setDragging(true);
            setDragStart(e.clientY);
        } else if (showDrawZone) {
            point_it(e);
        }
    };

    const handleMouseUp = () => {
        setDragging(false);
    };

    const handleContextMenu = (e) => {
        e.preventDefault();
        point_it(e);
    };

    const handleMouseMove = (e) => {
        if (isDragging) {
            const rect = e.target.getBoundingClientRect();
            const mouseY = e.clientY;

            const handleHeight = rect.height * barPercentage;

            // Calculate the change in mouseY since the last movement
            const deltaY = mouseY - dragStart;

            // Calculate the new position of the scrollbar-handle
            let percentage = (scrollPosition + deltaY * 0.15);

            // Ensure the scrollbar-handle cannot move beyond the bottom
            percentage = Math.min(100 - handleHeight / rect.height * 100, Math.max(0, percentage));

            const detectionSplitter = ((percentage + (barPercentage * 100)) * (720 / imageMultipier / 100)) * imageMultipier;
            setDetectionSplitter(detectionSplitter);
            setScrollPosition(percentage);
            setDragStart(mouseY);
        }
    };


    const scrollbarHandleStyle = {
        position: 'absolute',
        top: `${scrollPosition}%`,
        width: 1280 / imageMultipier,
        height: `${nanoConfigFormData?.['detection.splitter_width'] * 2 / imageMultipier || 0}px`, // detection.splitter.width
        backgroundColor: 'rgb(255,0,0,0.3)',
        border: '1px solid black',
        cursor: 'grab',
    };

    const scrollbarStyle = {
        position: 'absolute',
        top: 0,
        right: 0,
        height: 720 / imageMultipier,
        width: 1280 / imageMultipier,
        backgroundColor: 'rgb(0, 0, 0, 0)',
        cursor: isDragging ? 'grabbing' : 'grab',
    };

    const dispatch = useDispatch();

    useEffect(() => {
        if (nanoConfigFormData) {
            // dynamically assign the width and height to canvas
            const canvasEle = canvas.current;
            const dataURL = 'data:image/png;base64, ' + image;

            if (canvasEle) {
                canvasEle.style.backgroundImage = `url('${dataURL}')`;  // breaks when updating in IDE
                canvasEle.style.backgroundSize = "cover";

                // get context of the canvas

                ctx = canvasEle.getContext("2d");
            }


            // crossing line bar percentage
            setBarPercentage((nanoConfigFormData['detection.splitter_width'] * 2 / imageMultipier) / (720 / imageMultipier));


            const handleDocumentMouseMove = (e) => {
                handleMouseMove(e);
            };

            const handleDocumentMouseUp = () => {
                handleMouseUp();
            };

            if (isDragging) {
                document.addEventListener('mousemove', handleDocumentMouseMove);
                document.addEventListener('mouseup', handleDocumentMouseUp);
            }
            if (canvasEle && showDrawZone) {
                canvasEle.addEventListener('mousedown', handleMouseDown);
                canvasEle.addEventListener('contextmenu', handleContextMenu);
                start(true);
            }

            return () => {
                document.removeEventListener('mousemove', handleDocumentMouseMove);
                document.removeEventListener('mouseup', handleDocumentMouseUp);
                if (canvasEle) {
                    canvasEle.removeEventListener('contextmenu', handleContextMenu);
                    canvasEle.removeEventListener('mousedown', handleMouseDown);
                }
            };
        }
    }, [nanoConfigFormData, isDragging, handleMouseMove, handleMouseUp]);

    useEffect(() => {
        fetchConfiguration(props.deviceId, props.core);
    }, []);

    const fetchConfiguration = (device_id, core_id) => {
        setIsLoading(true);
        setIsNewConfigDownloadInitialized(false);
        GetDeviceConfiguration(device_id, core_id).then((result) => {
            if (result instanceof AxiosError) {
                if (result.response.data.data.code === 1) {
                    setIsNoData(true);
                    setConfig(null);
                }

                setIsLoading(false);
            } else {
                if (result.status === 200) {
                    setIsNoData(false);

                    setIsNewConfigDownloadInitialized(false);
                    console.log(result.data.data.config);
                    setConfig(result.data.data.config);

                    setConfigDate(result.data.data.config_date);
                    setParsedConfigDate(result.data.data.parsed_config_date);

                    setIsOutOfSync(result.data.data.out_of_sync);

                    // CORE CONF
                    let coreConfObjArr = {};

                    result.data.data.config.core_conf.forEach((item) => {
                        coreConfObjArr[item.key] = item.value;
                    });

                    setCoreConfigFormData(coreConfObjArr);

                    // END OF CORE CONF

                    // NANO CONF
                    let nanoConfObjArr = {};

                    result.data.data.config.nano_conf.forEach((item) => {
                        nanoConfObjArr[item.key] = item.value;
                    });

                    setNanoConfigFormData(nanoConfObjArr);
                    const percentage = (nanoConfObjArr['detection.splitter'] - nanoConfObjArr['detection.splitter_width'] * 2) / (720 / 100);
                    setScrollPosition(percentage);
                    // END OF CORE CONF

                    // IMAGE
                    setImage(result.data.data.image.snapshot);
                    // END OF IMAGE
                }

                setIsLoading(false);
            }
        });
    }

    const beginNewConfigurationDownload = (device_id, core_id) => {

        dispatch(enableLoading());
        // tell BMC to init download from device

        InitializeConfigDownloadQuery(device_id, core_id).then(() => {

            setIsNewConfigDownloadInitialized(true);
            dispatch(disableLoading());
        });

        setTimeout(() => {
            fetchConfiguration(props.deviceId, props.core);
        }, 30000);
    }

    const handleCoreConfigurationItemChange = (e) => {
        const value = e.target.value;
        setCoreConfigFormData({
            ...coreConfigFormData, [e.target.name]: value
        });
    }

    const handleNanoDetectionSplitterChange = (value) => {
        setNanoConfigFormData({
            ...nanoConfigFormData, "detection.splitter": Math.round(value)
        });

    }

    const handleNanoConfigurationItemChange = (e) => {
        const value = e.target.value;
        setNanoConfigFormData({
            ...nanoConfigFormData, [e.target.name]: value
        });
    }

    const handleCrossingLineChange = () => {
        setShowCrossingLine(showCrossingLine => !showCrossingLine);
        handleNanoDetectionSplitterChange(detectionSplitter);

    }

    const saveConfig = (component) => {
        saveConfigConfirmSWAL.fire({
            title: t("Are you sure?"),
            text: t("Invalid parameters can cause device malfunction"),
            icon: "warning",
            showCancelButton: true,
        })
            .then(res => {
                if (res.isConfirmed) {
                    dispatch(enableLoading());
                    SaveConfigQuery(props.deviceId, props.core, component, {"config": nanoConfigFormData}).then(() => {
                        dispatch(disableLoading());
                        saveConfigSavedSWAL.fire({
                            title: <strong>{t("Done")}</strong>,
                            text: t("Configuration saved successfully"),
                            icon: 'success'
                        }).then(() => {
                            fetchConfiguration(props.deviceId, props.core);
                        });
                    });
                }
            })
    }

    const syncConfig = () => {
        saveConfigConfirmSWAL.fire({
            title: t("Are you sure?"),
            text: t("Invalid parameters can cause device malfunction"),
            icon: "warning",
            showCancelButton: true,
        })
            .then(res => {
                if (res.isConfirmed) {
                    dispatch(enableLoading());
                    SyncConfigQuery(props.deviceId, props.core, {"config": coreConfigFormData}).then(() => {
                        dispatch(disableLoading());
                        saveConfigSavedSWAL.fire({
                            title: <strong>{t("Done")}</strong>, text: t("Configuration sync started"), icon: 'success'
                        }).then(() => {
                            fetchConfiguration(props.deviceId, props.core);
                        });
                    });
                }
            })
    }

    const loadZones = () => {
        ctx.clearRect(0, 0, 1280 / imageMultipier, 720 / imageMultipier);

        drawCrossingLine(nanoConfigFormData["detection.splitter"], nanoConfigFormData["detection.splitter_width"]);
        drawZone(nanoConfigFormData["detection.polygons.0"]);
    }

    const drawCrossingLine = (heigth, width) => {
        ctx.strokeStyle = "rgba(255,0,0,0.4)";
        ctx.lineWidth = width * 2 / imageMultipier;

        // draw a red line
        ctx.beginPath();
        ctx.moveTo(0, (heigth - width) / imageMultipier);
        ctx.lineTo(1280 / imageMultipier, (heigth - width) / imageMultipier);
        ctx.stroke();
    }


    const drawZone = (coordinates) => {
        var poly = [];

        var zonedata = JSON.parse(coordinates);

        if (zonedata) {
            zonedata.map((obj) => {
                poly.push(obj.x / imageMultipier);
                poly.push(obj.y / imageMultipier);
            });
        }

        console.log(zonedata);

// copy array
        var shape = poly.slice(0);

        ctx.fillStyle = "rgba(0,255,0,0.4)";
        ctx.beginPath();
        ctx.moveTo(shape.shift(), shape.shift());
        while (shape.length) {
            ctx.lineTo(shape.shift(), shape.shift());
        }
        ctx.closePath();

        ctx.fill();
    }

    const start = (withDraw) => {
        if (withDraw) {
            draw(false);
        }
    };

    const undo = () => {
        handlePop();
        setComplete(false);
        start(true);
        ctx.clearRect(0, 0, 1280 / imageMultipier, 720 / imageMultipier);
    };

    const handlePop = () => {
        const tempArray = [...perimeter.slice(0, -1)];
        setPerimeter(tempArray);
    };

    function clear_canvas() {
        setPerimeter([]);
        setComplete(false);
        ctx.clearRect(0, 0, 1280 / imageMultipier, 720 / imageMultipier);
    }

    const draw = (end) => {
        const canvasEle = canvas.current;
        const ctx = canvasEle.getContext('2d');
        ctx.lineWidth = 1;
        ctx.strokeStyle = 'white';
        ctx.lineCap = 'square';
        ctx.beginPath();

        for (let i = 0; i < perimeter.length; i++) {
            if (i === 0) {
                ctx.moveTo(perimeter[i]['x'], perimeter[i]['y']);
                end || point(perimeter[i]['x'], perimeter[i]['y']);
            } else {
                ctx.lineTo(perimeter[i]['x'], perimeter[i]['y']);
                end || point(perimeter[i]['x'], perimeter[i]['y']);
            }
        }

        if (end) {
            ctx.lineTo(perimeter[0]['x'], perimeter[0]['y']);
            ctx.closePath();
            ctx.fillStyle = 'rgba(255, 0, 0, 0.5)';
            ctx.fill();
            ctx.strokeStyle = 'blue';
            setComplete(true);
            //parseCoordinates();
        }
        ctx.stroke();
    };

    const point = (x, y) => {
        const ctx = canvas.current.getContext('2d');
        ctx.fillStyle = 'white';
        ctx.strokeStyle = 'white';
        ctx.fillRect(x - 2, y - 2, 4, 4);
        ctx.moveTo(x, y);
    };

    const point_it = (event) => {
        if (complete) {
            return false;
        }
        const canvasEle = canvas.current;
        const rect = canvasEle.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        if (event.ctrlKey || event.which === 3 || event.button === 2) {
            if (perimeter.length === 2) {
                return false;
            }
            if (perimeter.length > 2) {
                const startX = perimeter[0]['x'];
                const startY = perimeter[0]['y'];
                if (check_intersect(startX, startY, x, y)) {
                    return false;
                }
                draw(true);
                event.preventDefault();
                return false;
            }

        } else {
            if (perimeter.length > 0 && x === perimeter[perimeter.length - 1]['x'] && y === perimeter[perimeter.length - 1]['y']) {
                // Same point - double click
                return false;
            }
            if (check_intersect(x, y)) {
                return false;
            }
            handlePush(x, y);
            draw(false);
            return false;
        }
    };

    const handlePush = (x, y) => {
        const tempArray = [...perimeter, {'x': x, 'y': y, 'id': null, 'point': perimeter.length}];
        setPerimeter(tempArray);
    };

    const check_intersect = (x, y) => {
        if (perimeter.length < 4) {
            return false;
        }
        const p0 = {};
        const p1 = {};
        const p2 = {};
        const p3 = {};

        p2['x'] = perimeter[perimeter.length - 1]['x'];
        p2['y'] = perimeter[perimeter.length - 1]['y'];
        p3['x'] = x;
        p3['y'] = y;

        for (let i = 0; i < perimeter.length - 1; i++) {
            p0['x'] = perimeter[i]['x'];
            p0['y'] = perimeter[i]['y'];
            p1['x'] = perimeter[i + 1]['x'];
            p1['y'] = perimeter[i + 1]['y'];
            if (p1['x'] === p2['x'] && p1['y'] === p2['y']) {
                continue;
            }
            if (p0['x'] === p3['x'] && p0['y'] === p3['y']) {
                continue;
            }
            if (line_intersects(p0, p1, p2, p3)) {
                return true;
            }
        }
        return false;
    };

    const line_intersects = (p0, p1, p2, p3) => {
        const s1_x = p1['x'] - p0['x'];
        const s1_y = p1['y'] - p0['y'];
        const s2_x = p3['x'] - p2['x'];
        const s2_y = p3['y'] - p2['y'];

        const s = (-s1_y * (p0['x'] - p2['x']) + s1_x * (p0['y'] - p2['y'])) / (-s2_x * s1_y + s1_x * s2_y);
        const t = (s2_x * (p0['y'] - p2['y']) - s2_y * (p0['x'] - p2['x'])) / (-s2_x * s1_y + s1_x * s2_y);

        return s >= 0 && s <= 1 && t >= 0 && t <= 1;
    };

    // dummy
    const parseCoordinates = () => {
        let holder = [];

        perimeter.map((e) => {
            e.x = Math.round(e.x * imageMultipier);
            e.y = Math.round(e.y * imageMultipier);

            holder.push(e);
        });

        setNanoConfigFormData({
            ...nanoConfigFormData, "detection.polygons.0": JSON.stringify(holder)
        });

    }

    return (<>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header bg-primary">
                            <div className="row">
                                <div className="col-9 clickable" onClick={() => setIsCardOpen(!isCardOpen)}>
                                    <h4 className="card-title text-white"
                                        style={{margin: "6px 0px 0px 0px"}}>{t("Device configuration")}</h4>
                                </div>
                                <div className="col-3 text-end">
                                    <ButtonGroup>
                                        <Button
                                            className="btn btn-primary"
                                            onClick={() => setIsCardOpen(!isCardOpen)}
                                            aria-controls="collapsableMapCard"
                                            aria-expanded={isCardOpen}
                                        >
                                            {isCardOpen && <FontAwesomeIcon icon={faMinus}/>}
                                            {!isCardOpen && <FontAwesomeIcon icon={faPlus}/>}
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </div>

                        </div>
                        <Collapse in={isCardOpen}>
                            <div id="collapsableMapCard">
                                <div className="card-body">
                                    {isLoading && <>
                                        Loading...
                                    </>}
                                    {!isLoading && !isNewConfigDownloadInitialized && <>
                                        {isNoData && <>
                                            <Alert key="danger" variant="danger" className="clickable"
                                                   onClick={() => beginNewConfigurationDownload(props.deviceId, props.core)}>
                                                {t("No initial configuration found. Click here to begin configuration download from device.")}
                                            </Alert>

                                        </>}

                                        {!isNoData && <>
                                            <Alert key="info" variant="info" className="clickable"
                                                   onClick={() => beginNewConfigurationDownload(props.deviceId, props.core)}>
                                                {t("This is current configuration in BMC. Click here to update configuration from device.")}
                                                <br/>
                                                {t("Device configuration date: " + moment(configDate).format('YYYY-MM-DD HH:mm'))}
                                                <br/>
                                                {t("BMC configuration date: " + moment(parsedConfigDate).format('YYYY-MM-DD HH:mm'))}
                                            </Alert>

                                            {isOutOfSync > 0 &&
                                                <Alert key="danger" variant="danger" className="clickable"
                                                       onClick={() => syncConfig()}>
                                                    {t("Device configuration is out of sync, click here to sync with device.")}
                                                </Alert>}

                                            {config && <>

                                                <>
                                                    <Accordion className='custom-accordion'
                                                               defaultActiveKey="0">
                                                        {config.core_conf && <Accordion.Item eventKey="1">
                                                            <Accordion.Header>{t("Core configuration")}</Accordion.Header>
                                                            <Accordion.Body fluid>
                                                                <Form>

                                                                    {config.core_conf.map((item) => {
                                                                        if (item.key.includes("__COMMENT__")) {

                                                                        } else {

                                                                            return (<Row
                                                                                className="p-2">
                                                                                <Col lg={4}>
                                                                                    <Form.Label>
                                                                                        <b>{item.key.replaceAll(".", " ").replaceAll("_", " ")}</b>
                                                                                    </Form.Label>
                                                                                </Col>
                                                                                <Col lg={8}>
                                                                                    <Form.Control
                                                                                        type="text"
                                                                                        name={item.key}
                                                                                        onChange={handleCoreConfigurationItemChange}
                                                                                        disabled={item.locked}
                                                                                        value={coreConfigFormData[item.key]}/>
                                                                                </Col>
                                                                            </Row>)
                                                                        }
                                                                    })}
                                                                    <Row
                                                                        className="p-2">
                                                                        <Col lg={12}
                                                                             className="text-end">
                                                                            <Button variant="primary"
                                                                                    onClick={() => saveConfig('nano_conf')}
                                                                                    type="button">
                                                                                {t("Save configuration")}
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                </Form>
                                                            </Accordion.Body>
                                                        </Accordion.Item>}
                                                        {config.nano_conf && <Accordion.Item eventKey="2">
                                                            <Accordion.Header>{t("Nano configuration")}</Accordion.Header>


                                                            <Accordion.Body fluid
                                                                            className='nano-conf-acc-body'>
                                                                <Row>
                                                                    <Col lg={12} style={{
                                                                        padding: 0, position: 'relative'
                                                                    }}>

                                                                        <canvas id="canvas"
                                                                                height={720 / imageMultipier}
                                                                                width={1280 / imageMultipier}
                                                                                ref={canvas}
                                                                                style={{
                                                                                    cursor: 'crosshair',
                                                                                    border: '1px solid black'
                                                                                }}>
                                                                        </canvas>
                                                                        {showCrossingLine && <div
                                                                            className='scrollbar-rect'
                                                                            style={scrollbarStyle}
                                                                            onMouseDown={handleMouseDown}
                                                                        >

                                                                            {nanoConfigFormData && <div
                                                                                className='scrollbar-handle'
                                                                                style={scrollbarHandleStyle}></div>}
                                                                        </div>}
                                                                    </Col>


                                                                </Row>
                                                                <Row
                                                                    className="p-2">
                                                                    <Col lg={12}
                                                                         className="">
                                                                        <Button variant="primary"
                                                                                className="p-2"
                                                                                style={{marginRight: "5px"}}
                                                                                onClick={() => loadZones()}
                                                                                type="button"
                                                                                disabled={showCrossingLine || showDrawZone}>
                                                                            {t("Load zones")}
                                                                        </Button>
                                                                        <Button variant="primary"
                                                                                className="p-2"
                                                                                style={{marginRight: "5px"}}
                                                                                type="button"
                                                                                disabled={showCrossingLine}
                                                                                onClick={() => {
                                                                                    if (!showDrawZone) {
                                                                                        clear_canvas()
                                                                                    }
                                                                                    setShowDrawZone(showDrawZone => !showDrawZone);
                                                                                }}>
                                                                            {showDrawZone ? <>{t("Confirm")}</> : <>{t("Draw new zone")}</>}
                                                                        </Button>
                                                                        {showDrawZone && <>
                                                                            <Button onClick={() => {
                                                                                undo();
                                                                            }}>
                                                                                Undo
                                                                            </Button>
                                                                            <Button style={{
                                                                                marginLeft: "2px", marginRight: "5px"
                                                                            }} onClick={() => {
                                                                                clear_canvas();
                                                                            }}>
                                                                                Clear
                                                                            </Button>
                                                                        </>

                                                                        }
                                                                        <Button variant="primary"
                                                                                className="p-2"
                                                                                style={{marginRight: "5px"}}
                                                                                onClick={() => {
                                                                                    handleCrossingLineChange();
                                                                                }}
                                                                                type="button"
                                                                                disabled={showDrawZone}>
                                                                            {showCrossingLine ? <>{t("Confirm")}</> : <>{t("Move crossing line")}</>}

                                                                        </Button>
                                                                        <Button variant="primary"
                                                                                className="p-2"
                                                                                style={{marginRight: "5px"}}
                                                                                onClick={() => {
                                                                                    ctx.clearRect(0, 0, 1280 / imageMultipier, 720 / imageMultipier);
                                                                                    parseCoordinates();
                                                                                }}
                                                                                type="button"
                                                                                disabled={showDrawZone}>
                                                                            {t("Clear image")}
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                                <Form>
                                                                    {config.nano_conf.map((item) => {
                                                                        if (item.key.includes("__COMMENT__")) {

                                                                        } else {

                                                                            return (<Row
                                                                                className="p-2">
                                                                                <Col lg={4}>
                                                                                    <Form.Label>
                                                                                        <b>{item.key.replaceAll(".", " ").replaceAll("_", " ")}</b>
                                                                                    </Form.Label>
                                                                                </Col>
                                                                                <Col lg={8}>
                                                                                    <Form.Control
                                                                                        type="text"
                                                                                        name={item.key}
                                                                                        onChange={handleNanoConfigurationItemChange}
                                                                                        disabled={item.locked}
                                                                                        value={nanoConfigFormData[item.key]}/>
                                                                                </Col>
                                                                            </Row>)
                                                                        }
                                                                    })}
                                                                    <Row
                                                                        className="p-2">
                                                                        <Col lg={12}
                                                                             className="text-end">
                                                                            <Button variant="primary"
                                                                                    onClick={() => saveConfig('nano_conf')}
                                                                                    type="button">
                                                                                {t("Save configuration")}
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                </Form>
                                                            </Accordion.Body>
                                                        </Accordion.Item>}

                                                        <Accordion.Item eventKey="3">
                                                            <Accordion.Header>{t("Telemetry configuration")}</Accordion.Header>
                                                            <Accordion.Body fluid>

                                                            </Accordion.Body>
                                                        </Accordion.Item>

                                                        <Accordion.Item eventKey="4">
                                                            <Accordion.Header>{t("Radar configuration")}</Accordion.Header>
                                                            <Accordion.Body fluid>

                                                            </Accordion.Body>
                                                        </Accordion.Item>

                                                    </Accordion>


                                                </>

                                            </>}
                                        </>}
                                    </>}

                                    {isNewConfigDownloadInitialized && <>
                                        <>
                                            <Alert key="info" variant="info" className="">
                                                {t("Configuration download initialized... Please wait for configuration download...")}
                                            </Alert>
                                        </>
                                    </>}
                                </div>
                            </div>
                        </Collapse>
                    </div>
                </div>
            </div>
        </>

    );
};

export default InteractiveSettings;