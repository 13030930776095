import React, {useEffect, useState, useRef} from 'react';
import {Form} from 'react-bootstrap';
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";

const MultiSelectDropdown = (props) => {
    const {t} = useTranslation();
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        props.setSelected(selectedOptions);
    }, [selectedOptions, props]);

    const ref = useRef(null);
    useEffect(() => {
        const checkIfClickOutside = (e) => {
            if (isOpen && ref.current && !ref.current.contains(e.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("click", checkIfClickOutside);
        return () => {
            document.removeEventListener("click", checkIfClickOutside);
        }
    }, [isOpen])

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionChange = (event) => {
        const optionId = parseInt(event.target.value);
        const isChecked = event.target.checked;

        if (isChecked) {
            setSelectedOptions([...selectedOptions, optionId]);
        } else {
            setSelectedOptions(selectedOptions.filter((id) => id !== optionId));
        }
    };

    return (<Form.Group ref={ref} className="mb-3" controlId="multiselectCtl">
            <Form.Label onClick={() => {
                toggleDropdown()
            }} style={{cursor: "pointer"}}>
                {t(props.name)}
                &nbsp;
                {isOpen && <FontAwesomeIcon icon={faChevronUp}></FontAwesomeIcon>}
                {!isOpen && <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon>}
            </Form.Label>
            <div className={`dropdown ${isOpen ? 'show' : ''}`}>
                <div style={{width: "20%"}} className={`dropdown-menu ${isOpen ? 'show' : ''}`}
                     aria-labelledby="multiSelectDropdown">
                    {props.options.map((option) => (<Form.Check
                        style={{marginLeft: "10%"}}
                        key={option.id}
                        type="checkbox"
                        id={`option_${option.id}`}
                        label={option.value}
                        checked={selectedOptions.includes(option.id)}
                        onChange={handleOptionChange}
                        value={option.id}
                    />))}
                </div>
            </div>
        </Form.Group>

    );
};

export default MultiSelectDropdown;