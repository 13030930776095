import React, {useEffect, useState} from 'react';
import './Users.css';
import UsersTable from "../../components/UsersTable/UsersTable";
import GroupsTable from "../../components/GroupsTable/GroupsTable";


export default function Users() {
    const [currentUser, setCurrentUser] = useState();
    const [currentGroup, setCurrentGroup] = useState();

    useEffect(() => {
        setCurrentUser(JSON.parse(localStorage.getItem("me")));
        setCurrentGroup(JSON.parse(localStorage.getItem("current_group")));
    }, []);

    return (<>
        {currentUser && <>
            <UsersTable currentUser={currentUser} currentGroup={currentGroup}/>
            <GroupsTable currentUser={currentUser} currentGroup={currentGroup}/>
        </>}
    </>);
}