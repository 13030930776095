import './NotificationTable.css';
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from 'react';
import DataTable from "react-data-table-component";
import {faRotate} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {disableLoading, enableLoading} from "../../features/loading/loadingSlice";
import GetUserNotificationsLimitedQuery from '../../queries/UserNotifications/GetUserNotificationsLimited';
import moment from 'moment/moment';

export default function NotificationTable() {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [dataTableData, setDataTableData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    useEffect(() => {
        fetchNotifications(1); // load first page 
    }, []);

    const conditionalRowStyles = [{
        when: row => row.new, style: {
            backgroundColor: "#e88383"
        }
    }];

    const columns = [{
        selector: row => row.new, sortable: true, width: "0px",
    }, {
        name: t('Device'), selector: row => row.device
    }, {
        name: t('Error'), selector: row => row.error
    }, {
        name: t('Created at'), selector: row => row.created_at, sortable: true
    }, {
        name: t('Updated at'), selector: row => row.updated_at, sortable: true
    }];

    const formatDate = (dateString) => {
        return moment(dateString).format('YYYY/MM/DD - HH:mm:ss');
    }

    const fetchNotifications = async page => {
        dispatch(enableLoading());

        GetUserNotificationsLimitedQuery(page, perPage).then((response) => {
            if (response.status === 200) {
                let data = response.data.data.notifications;
                setTotalRows(response.data.data.total);

                let dataTableArray = [];
                for (const [, value] of Object.entries(data)) {
                    let row = {
                        created_at: formatDate(value.created_at),
                        updated_at: formatDate(value.updated_at),
                        device: value.device.name,
                        error: value.component,
                        new: !value.seen ? " " : ""
                    }
                    dataTableArray.push(row);
                }
                setDataTableData(dataTableArray);
                dispatch(disableLoading());
            }
        });
    }

    const handlePageChange = page => {
        fetchNotifications(page);
    }

    const handlePerRowsChange = async (newPerPage, page) => {
        dispatch(enableLoading());

        GetUserNotificationsLimitedQuery(page, newPerPage).then((response) => {
            if (response.status === 200) {
                let data = response.data.data.notifications;
                setPerPage(newPerPage);

                let dataTableArray = [];
                for (const [, value] of Object.entries(data)) {
                    let row = {
                        created_at: formatDate(value.created_at),
                        updated_at: formatDate(value.updated_at),
                        device: value.device.name,
                        error: value.component,
                        new: !value.seen ? " " : ""
                    }
                    dataTableArray.push(row);
                }
                setDataTableData(dataTableArray);
                dispatch(disableLoading());
            }
        });
    }

    return (<>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header bg-primary">
                            <div className="row">
                                <div className="col-9">
                                    <h4 className="card-title text-white">{t("Notifications")}</h4>
                                </div>
                                <div className="col-3 text-end">
                                    <button type="button" className="btn btn-primary btn-icon-square-sm"
                                            onClick={() => fetchNotifications(1)}>
                                        <FontAwesomeIcon icon={faRotate}/>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="card-body">
                            <div className="table-responsive">
                                <DataTable
                                    columns={columns}
                                    data={dataTableData}
                                    responsive={true}
                                    conditionalRowStyles={conditionalRowStyles}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={totalRows}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    onChangePage={handlePageChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )

}