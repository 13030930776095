import React, {useEffect} from 'react';
import './LanguageSelect.css';
import {useTranslation} from "react-i18next";
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';

export default function LanguageSelect(props) {
    const {t, i18n} = useTranslation();

    useEffect(() => {
        if (localStorage.getItem("i18nextLng")) {
            i18n.changeLanguage(localStorage.getItem("i18nextLng"));
        }
    }, []);

    const changeLanguage = (lng) => {
        localStorage.setItem("i18nextLng", lng);
        i18n.changeLanguage(lng);
    }

    const dropdownTitle = (<>
        {i18n.language === "ee" && <>
            {props.width > 450 && <> {t("Estonian")}{" "} </>}
            <img
                src="/images/flags/estonia_flag.png"
                className="ml-2"
                height={"16"}
                width={"24"}
                alt=""
            />{" "}
            <i className="mdi mdi-chevron-down"></i>
        </>}
        {i18n.language === "en" && <>
            {props.width > 450 && <> {t("English")}{" "} </>}
            <img
                src="/images/flags/us_flag.jpg"
                className="ml-2"
                height={"16"}
                width={"24"}
                alt=""
            />{" "}
            <i className="mdi mdi-chevron-down"></i>
        </>}
        {!i18n.language && <>
            {props.width > 450 && <> {t("English")}{" "} </>}
            <img
                src="/images/flags/us_flag.jpg"
                className="ml-2"
                height={"16"}
                width={"24"}
                alt=""
            />{" "}
            <i className="mdi mdi-chevron-down"></i>
        </>}
        {i18n.language === "cr" && <>
            {props.width > 450 && <> {t("Croatian")}{" "} </>}
            <img
                src="/images/flags/croatia-flag.jpg"
                className="ml-2"
                height={"16"}
                width={"24"}
                alt=""
            />{" "}
            <i className="mdi mdi-chevron-down"></i>
        </>}
    </>);

    return (<>
        <Nav>
            <NavDropdown className="custom-dropdown" title={dropdownTitle}>

                {i18n.language === "ee" && <>
                    <NavDropdown.Item onClick={() => changeLanguage("en")}>
                        <span> {t("English")} </span>
                        <img
                            src="/images/flags/us_flag.jpg"
                            alt=""
                            className="ml-2 float-right"
                            height={"14"}
                            width={"21"}
                        />
                    </NavDropdown.Item>

                    <NavDropdown.Item onClick={() => changeLanguage("cr")}>
                        <span> {t("Croatian")} </span>
                        <img
                            src="/images/flags/croatia-flag.jpg"
                            alt=""
                            className="ml-2 float-right"
                            height={"14"}
                            width={"21"}
                        />
                    </NavDropdown.Item>
                </>}

                {i18n.language === "en" && <>
                    <NavDropdown.Item onClick={() => changeLanguage("ee")}>
                        <span> {t("Estonian")} </span>
                        <img
                            src="/images/flags/estonia_flag.png"
                            alt=""
                            className="ml-2 float-right"
                            height={"14"}
                            width={"21"}
                        />
                    </NavDropdown.Item>

                    <NavDropdown.Item onClick={() => changeLanguage("cr")}>
                        <span> {t("Croatian")} </span>
                        <img
                            src="/images/flags/croatia-flag.jpg"
                            alt=""
                            className="ml-2 float-right"
                            height={"14"}
                            width={"21"}
                        />
                    </NavDropdown.Item>
                </>}

                {i18n.language === "cr" && <>
                    <NavDropdown.Item onClick={() => changeLanguage("ee")}>
                        <span> {t("Estonian")} </span>
                        <img
                            src="/images/flags/estonia_flag.png"
                            alt=""
                            className="ml-2 float-right"
                            height={"14"}
                            width={"21"}
                        />
                    </NavDropdown.Item>

                    <NavDropdown.Item onClick={() => changeLanguage("en")}>
                        <span> {t("English")} </span>
                        <img
                            src="/images/flags/us_flag.jpg"
                            alt=""
                            className="ml-2 float-right"
                            height={"14"}
                            width={"21"}
                        />
                    </NavDropdown.Item>
                </>}
            </NavDropdown>
        </Nav>
    </>);
}