import './NotificationsIcon.css';
import {Badge} from "@mui/material";
import {Link} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import GetUserNotificationsCountQuery from '../../queries/UserNotifications/GetUserNotificationsCount';

export default function NotificationsIcon() {
    const [notificationsCount, setNotificationsCount] = useState();

    useEffect(() => {
        fetchNotifications();
    }, []);

    const fetchNotifications = () => {

        GetUserNotificationsCountQuery().then((response) => {
            if (response.status === 200) {

                let data = response.data.data;
                setNotificationsCount(data);

                setTimeout(fetchNotifications, 10000);
            }
        });
    }

    return (<>
        <li>
            <Link to='/notifications'>
                <a className="nav-link waves-effect waves-light" style={{padding: "0px 12px 0px 0px"}}>
                    <Badge badgeContent={notificationsCount} color={"error"}>
                        <NotificationImportantIcon color="action"/>
                    </Badge>
                </a>
            </Link>
        </li>
    </>);
}