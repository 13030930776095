import React, {useEffect, useState} from 'react';
import './NutiteePublic.css';
import {useSelector} from "react-redux";
import {Button, Col, Container, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import Puu from "../../assets/icons/Puu.svg";
import Bird from "../../assets/icons/Bird.svg";
import {Blocks} from "react-loader-spinner";
import GetPublicData from "../../queries/Nutitee/GetPublicData";


export default function NutiteePublic() {
    const navigate = useNavigate();
    const nutitee = true;
    const [width, setWidth] = useState();

    const [AVGData, setAVGData] = useState({
        'avg_speed': 0,
        'speedings': 0,
        'overspeed': 0
    });

    var bodyElement;

    useEffect(() => {
        document.body.classList.add("account-body");
        document.body.classList.add("login-page");

        if (nutitee) {
            bodyElement = document.querySelector("body");
            bodyElement.style.background = "linear-gradient(150deg, rgba(27, 153, 19, 1) 50%, rgba(255, 255, 255, 1) 50%)";
            bodyElement.style.height = "100vh"
        }

        const abortController = new AbortController();
        const signal = abortController.signal;

        getPublicData(signal);

        return () => {
            abortController.abort()
        }
    }, []);


    const getPublicData = (signal) => {
        GetPublicData(signal).then((res) => {
            setAVGData(prevState => {
                return ({
                    ...prevState,
                    ...res.data.data
                })
            })
        })
    }

    return (
        <Container className="login-color">

            <Row style={{width: "100%"}}>

                <Col className="form-col">
                    <div className="auth-page">
                        <div className="card auth-card shadow-none" style={{width: "80vh", left: "-17vh"}}>
                            <div
                                className="card-body"
                                style={{
                                    background: "#ffffff", borderRadius: "20px", boxShadow: "8px 8px 10px #5c5c5c",
                                }}
                            >
                                <div className="px-3">
                                    <div className="auth-logo-box">
                                        <a href="/" className="logo logo-admin">
                                            <img
                                                src="/images/logo-sm.png"
                                                height="94"
                                                alt="logo"
                                                className="auth-logo"
                                            />
                                        </a>
                                    </div>
                                    <div className="text-center auth-logo-text">
                                        <h4 className="mt-0 mt-5">Põlva Nutitee</h4>
                                    </div>
                                    <Row>
                                        <Col md={4} className="text-center">
                                            <span className="tekstikastid">Keskmine kiirus</span>
                                        </Col>
                                        <Col md={4} className="text-center">
                                            <span className="tekstikastid">Kiiruseületajate osakaal</span>
                                        </Col>
                                        <Col md={4} className="text-center">
                                            <span className="tekstikastid">Üle 65 km/h osakaal</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4} className="text-center">
                                            <span className="numberCircle3 clrGreen"><span>{AVGData.avg_speed} km/h</span></span>
                                        </Col>
                                        <Col md={4} className="text-center">
                                            <span className="numberCircle2 clrOrange"><span>{AVGData.speedings}%</span></span>
                                        </Col>
                                        <Col md={4} className="text-center">
                                            <span className="numberCircle2 clrRose"><span>{AVGData.overspeed}%</span></span>
                                        </Col>
                                    </Row>
                                    <div className="card-body mt-1">
                                        <>
                                            <script
                                                src={"https://airly.org/map/airly.js"}
                                                type="text/javascript"/>

                                            <iframe id="airly_1773775892"
                                                    src="https://airly.org/widget/v2/?width=280&height=640&displayMeasurements=true&displayCAQI=true&autoWidth=true&language=en&indexType=AIRLY_CAQI&unitSpeed=si&unitTemperature=celsius&latitude=58.054321&longitude=27.055123&locationId=64042"
                                                    style={{width: "100%", height: "360px", border: "none"}}/>

                                        </>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-12 mt-2" style={{textAlign: "center"}}>
                                            <Button
                                                className="log-in-button"
                                                onClick={() => navigate("/login")}
                                            >
                                                <>
                                                    {"Logi sisse "}
                                                    <i className="fas fa-sign-in-alt ml-1"></i>
                                                </>


                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col></Row></Container>
    );
}