import React, {useEffect, useState} from 'react';
import './ProfileView.css';
import {useTranslation} from "react-i18next";
import {Container, Card, Button} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil} from "@fortawesome/free-solid-svg-icons";
import DataTable from "react-data-table-component";
import {Modal, ModalBody, ModalHeader, Form} from "react-bootstrap";
import {useDispatch} from "react-redux";
import {disableLoading, enableLoading} from "../../features/loading/loadingSlice";
import withReactContent from "sweetalert2-react-content";
import swal from 'sweetalert2';
import EditUserQuery from "../../queries/User/EditUser";

export default function ProfileView() {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [currentUser, setCurrentUser] = useState();
    const [editModalShow, setEditModalShow] = useState(false);
    const [dataTableData, setDataTableData] = useState([]);
    const userAddMessage = withReactContent(swal);
    const [currentGroups, setCurrentGroups] = useState();

    const [email, setEmail] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [description, setDescription] = useState();

    const [emailPlaceholder, setEmailPlaceholder] = useState();
    const [firstNamePlaceholder, setFirstNamePlaceholder] = useState();
    const [lastNamePlaceholder, setLastNamePlaceholder] = useState();
    const [descriptionPlaceholder, setDescriptionPlaceholder] = useState();

    const [currentPassword, setCurrentPassword] = useState();
    const [newPassword, setNewPassword] = useState();
    const [verifyNewPassword, setVerifyNewPassword] = useState();

    useEffect(() => {
        setCurrentUser(JSON.parse(localStorage.getItem("me")));
    }, []);

    useEffect(() => {
        fetchGroups();
    }, [currentUser]);

    useEffect(() => {
        if (currentUser) {

            setEmail(currentUser.email);
            setFirstName(currentUser.first_name);
            setLastName(currentUser.last_name);
            setDescription(currentUser.description);

            setEmailPlaceholder(currentUser.email);
            setFirstNamePlaceholder(currentUser.first_name);
            setLastNamePlaceholder(currentUser.last_name);
            setDescriptionPlaceholder(currentUser.description);

            setCurrentPassword(null);

            let sg = [];

            for (const [, value] of Object.entries(currentUser.groups)) {
                sg.push(value.id);
            }

            setCurrentGroups(sg);
        }
    }, [currentUser]);

    const columns = [{
        selector: 'name', cell: row => <div style={{fontSize: 14, color: '#50649c'}}> {row.name} </div>
    },];

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }
    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    }
    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
    }
    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    }
    const handleCurrentPasswordChange = (e) => {
        setCurrentPassword(e.target.value);
    }
    const handleNewPasswordChange = (e) => {
        setNewPassword(e.target.value);
    }
    const handleVerifyNewPasswordChange = (e) => {
        setVerifyNewPassword(e.target.value);
    }

    const cleanAndCloseModal = () => {
        setCurrentPassword(null);
        setNewPassword(null);
        setVerifyNewPassword(null);
        setEditModalShow(false);
    }

    const saveUser = () => {
        dispatch(enableLoading());

        let errMessage;

        if (!currentPassword && newPassword && newPassword === verifyNewPassword) {
            errMessage = "Current password field is empty";
            userAddMessage.fire({
                title: <strong>{t("Error")}</strong>, html: <i>{t(errMessage)}</i>, icon: 'error'
            }).then();
        } else if (newPassword !== verifyNewPassword) {
            errMessage = "New passwords don't match";
            userAddMessage.fire({
                title: <strong>{t("Error")}</strong>, html: <i>{t(errMessage)}</i>, icon: 'error'
            }).then();
        } else if (!newPassword && currentPassword) {
            errMessage = "New passwords field is empty";
            userAddMessage.fire({
                title: <strong>{t("Error")}</strong>, html: <i>{t(errMessage)}</i>, icon: 'error'
            }).then();
        } else {
            EditUserQuery(currentUser.id, email, firstName, lastName, currentPassword, newPassword, description, currentGroups).then((response) => {
                handleServerResponse(response);
            })
            return;
        }
        dispatch(disableLoading());
    }

    const handleServerResponse = (response) => {
        if (response.status !== 200 && response.status !== 201) {

            let message = response.response.data?.message;

            if (message === "Validation error") {
                let data = response.response.data.data;

                message = "";

                for (const [, value] of Object.entries(data)) {
                    message = message + value[0] + "<br/>";
                }
            }
            dispatch(disableLoading());
            userAddMessage.fire({
                title: <strong>{t("Error")}</strong>, html: message, icon: 'error'
            }).then();

            return;
        }

        setFirstNamePlaceholder(firstName);
        setLastNamePlaceholder(lastName);
        setEmailPlaceholder(email);
        setDescriptionPlaceholder(description);

        cleanAndCloseModal();
        dispatch(disableLoading());

        userAddMessage.fire({
            title: <strong>{t("Settings changed")}</strong>, icon: 'success', showConfirmButton: false, timer: 2000,
        }).then()
    }

    const fetchGroups = () => {
        if (currentUser) {

            let groups = currentUser.groups;

            let dataTableArray = [];
            let num = 1;

            for (const [, value] of Object.entries(groups)) {
                let row = {
                    name: num + '. ' + value.name,
                }

                dataTableArray.push(row);
                num++;
            }
            setDataTableData(dataTableArray);
        }
    }

    return (<>
        <Container className='profile-container'>
            <Card style={{textAlign: 'center'}} className='profile-card'>
                <Card.Img
                    variant="top"
                    src="/images/logo-sm.png"
                    alt="profile-user"
                    className="rounded-circle"
                    style={{
                        width: '9rem',
                        height: '9rem',
                        alignSelf: "center",
                        position: "relative",
                        top: "15px",
                        boxShadow: "5px 5px 8px rgba(46, 46, 46, 0.62)"
                    }}
                />
                <Card.Body style={{color: '#50649c'}}>
                    {currentUser && <Card.Title>
                        <> {firstNamePlaceholder} {lastNamePlaceholder} </>
                    </Card.Title>}
                    {currentUser && <Card.Text style={{fontSize: "15px"}}>
                        <> {emailPlaceholder} </>
                    </Card.Text>}
                    {currentUser && <Card.Text className='text-center' style={{fontSize: "13px"}}>
                        <> {descriptionPlaceholder} </>
                    </Card.Text>}
                    <Card.Text className='profile-groups-title'> {t('Groups')}  </Card.Text>

                    <DataTable className='profile-groups-table' noTableHead={true} columns={columns}
                               data={dataTableData}/>

                    {/** ToDo: Button lose focus after click */}
                    <Button className="edit-profile-button" onClick={() => setEditModalShow(true)}>
                        <FontAwesomeIcon icon={faPencil} style={{color: '#FFFFFF'}}/>
                    </Button>

                </Card.Body>
            </Card>
        </Container>

        <Modal show={editModalShow} className="edit-profile-modal" onHide={cleanAndCloseModal}>
            <ModalHeader>
                <Modal.Title> Edit profile </Modal.Title>
            </ModalHeader>
            <ModalBody>
                <Form.Group className="mb-3" controlId="emailCtl">
                    <Form.Label>{t("E-mail")}</Form.Label>
                    <Form.Control type="name" placeholder={t("Enter e-mail")} value={email}
                                  onChange={handleEmailChange}/>
                </Form.Group>

                <Form.Group className="mb-3" controlId="firstNameCtl">
                    <Form.Label>{t("First name")}</Form.Label>
                    <Form.Control type="name" placeholder={t("Enter first name")} value={firstName}
                                  onChange={handleFirstNameChange}/>
                </Form.Group>

                <Form.Group className="mb-3" controlId="lastNameCtl">
                    <Form.Label>{t("Last name")}</Form.Label>
                    <Form.Control type="name" placeholder={t("Enter last name")} value={lastName}
                                  onChange={handleLastNameChange}/>
                </Form.Group>

                <Form.Group className="mb-3" controlId="descriptionCtl">
                    <Form.Label>{t("Description")}</Form.Label>
                    <Form.Control type="name" placeholder={t("Enter description")} value={description}
                                  onChange={handleDescriptionChange}/>
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>{t("Change password")}</Form.Label>
                    <Form.Control type="password" placeholder={t("Current password")}
                                  onChange={handleCurrentPasswordChange}/>
                    <Form.Control style={{position: 'relative', top: '10px'}}
                                  type="password" placeholder={t("New password")}
                                  onChange={handleNewPasswordChange}/>
                    <Form.Control style={{position: 'relative', top: '10px'}}
                                  type="password" placeholder={t("New password")}
                                  onChange={handleVerifyNewPasswordChange}/>
                </Form.Group>

            </ModalBody>
            <Modal.Footer>
                <Button variant="secondary" onClick={cleanAndCloseModal}>
                    {t("Close")}
                </Button>
                <Button variant="primary" onClick={() => saveUser()}>
                    {t("Save")}
                </Button>
            </Modal.Footer>
        </Modal>

    </>);
}