export default class Line {
    defaultRadius = 5
    defaultGradient = {
        0.4: 'blue',
        0.6: 'cyan',
        0.7: 'green',
        0.8: 'yellow',
        1.0: 'red',
    }

    constructor(canvas) {
        this._canvas = canvas = typeof canvas === 'string' ? document.getElementById(canvas) : canvas;

        this._ctx = canvas.getContext('2d');
        this._width = canvas.width;
        this._height = canvas.height;

        this._max = 1;
        this._data = [];
    }

    data(data) {
        this._data = data;

        return this;
    }

    clear() {
        this._data = [];

        return this;
    }

    radius(r, blur) {
        blur = blur === undefined ? 8 : blur;

        let circle = this._circle = this._createCanvas();
        let ctx = circle.getContext('2d');
        let r2 = this._r = r + blur;

        circle.width = circle.height = r2 * 2;

        ctx.shadowOffsetX = ctx.shadowOffsetY = r2 * 2;
        ctx.shadowBlur = blur;
        ctx.shadowColor = 'black';

        return this;
    }

    resize() {
        this._width = this._canvas.width;
        this._height = this._canvas.height;
    }

    gradient(grad) {
        let canvas = this._createCanvas();
        let ctx = canvas.getContext('2d');
        let gradient = ctx.createLinearGradient(0, 0, 0, 256);

        canvas.width = 1;
        canvas.height = 256;

        for(let i in grad) {
            gradient.addColorStop(+i, grad[i]);
        }

        ctx.fillStyle = gradient;
        ctx.fillRect(0, 0, 256, 256);

        this._grad = ctx.getImageData(0, 0, 1, 256).data;

        return this;
    }

    line(ctx, a, b) {
        ctx.beginPath();
        ctx.moveTo(a[0], a[1]);
        ctx.lineWidth = this._r;
        ctx.lineCap = "round";
        ctx.lineTo(b[0], b[1]);
        ctx.stroke();
        ctx.closePath();

        return this;
    }

    draw(minOpacity) {
        if (!this._circle)
            this.radius(this.defaultRadius);

        if (!this._grad)
            this.gradient(this.defaultGradient);

        let ctx = this._ctx;
        ctx.clearRect(0, 0, this._width, this._height);

        for(let line of this._data) {
            for (let i = 0, len = line.length - 1; i < len; i++) {
                let p1 = line[i];
                let p2 = line[i + 1];
                let gradientPos = this._scaleToGradient(p1[2]);

                ctx.strokeStyle = `rgb(${this._grad[gradientPos]}, ${this._grad[gradientPos + 1]}, ${this._grad[gradientPos + 2]})`;
                this.line(ctx, p1, p2);
            }
        }

        let colored = ctx.getImageData(0, 0, this._width, this._height);

        ctx.putImageData(colored, 0, 0);

        return this;
    }

    _scaleToGradient(val) {
        let value = Math.floor(255 * val);

        return value >= 255 ? 255 * 4 : value * 4;
    }

    _createCanvas() {
        if(typeof document !== 'undefined')
            return document.createElement('canvas');
        else
            return new this._canvas.constructor();
    }
}