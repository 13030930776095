import {Navigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {showNutitee} from "../../features/nutitee/nutiteeSlice";

const ProtectedRoute = ({children}) => {
    const dispatch = useDispatch();

    if (!localStorage.getItem('bearer-token') || !localStorage.getItem('me')) {
        if (window.location.href.includes("nutitee")) {
            dispatch(showNutitee());
            return <Navigate to="/nutitee-public" replace/>;
        }

        return <Navigate to="/login" replace/>;
    }

    return children;
};

export default ProtectedRoute