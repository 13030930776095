import {CONFIG} from "../../config";
import axios from "axios";

async function AddGroupQuery(name, description, master_id, is_admin, devices) {
    const data = {
        'name': name,
        'description': description,
        'master_id': master_id,
        'is_admin': is_admin,
        'devices': devices
    }

    const location = CONFIG.API_URL + "group/add";
    const settings = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('bearer-token')}`,
        }
    };
    try {
        return await axios.post(location, data, settings);
    } catch (e) {
        return e;
    }
}

export default AddGroupQuery;