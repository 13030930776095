import React, {useEffect, useState} from 'react';
import './UsersTable.css';
import {useTranslation} from "react-i18next";
import DataTable from "react-data-table-component";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAdd, faLock, faPencil, faRotate, faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {Button} from "react-bootstrap";
import {ButtonGroup} from "@mui/material";
import {useDispatch} from "react-redux";
import {disableLoading, enableLoading} from "../../features/loading/loadingSlice";
import GetGroupsQuery from "../../queries/Groups/GetGroups";
import GetUsersQuery from "../../queries/User/GetUsers";
import withReactContent from "sweetalert2-react-content";
import swal from "sweetalert2";
import DeleteUserQuery from "../../queries/User/DeleteUser";
import AddUserModal from "./Modals/AddUserModal";
import ResetPasswordQuery from "../../queries/User/ResetPassword";


export default function UsersTable(props) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [currentGroup] = useState(props.currentGroup);
    const [dataTableData, setDataTableData] = useState([]);
    const [addModalShow, setAddModalShow] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [groups, setGroups] = useState([]);
    const userPasswordResetConfirmSwal = withReactContent(swal);
    const userDeleteConfirmSwal = withReactContent(swal);
    const userDeleteErrorMessage = withReactContent(swal);
    const userDeleteConfirmMessage = withReactContent(swal)
    const userPasswordErrorMessage = withReactContent(swal);
    const userPasswordConfirmMessage = withReactContent(swal)

    useEffect(() => {
        fetchUsers();
        fetchGroups();
    }, []);

    const columns = [{
        name: t('E-mail'), selector: row => row.email, sortable: true,
    }, {
        name: t('First name'), selector: row => row.first_name, sortable: true, wrap: true
    }, {
        name: t('Last name'), selector: row => row.last_name, sortable: true, wrap: true
    }, {
        name: t('Description'), selector: row => row.description, sortable: true, wrap: true
    }, {
        name: t('Groups'), selector: row => row.groups, sortable: true, wrap: true
    }, {
        name: '', selector: row => row.buttons, sortable: false, right: true, width: "155px"
    }];

    const deleteUser = (id) => {
        //fist show confirm
        userDeleteConfirmSwal.fire({
            title: t("Are you sure?"), text: t("User will be deleted"), icon: "error", showCancelButton: true,
        })
            .then(res => {
                if (res.isConfirmed) {
                    //then show loading
                    dispatch(enableLoading());

                    //generate
                    DeleteUserQuery(id).then((response) => {
                        if (response.status !== 200 && response.status !== 201) {
                            dispatch(disableLoading());

                            let message = response.response.data?.message;

                            userDeleteErrorMessage.fire({
                                title: <strong>{t("Error")}</strong>, html: <i>{t(message)}</i>, icon: 'error'
                            }).then();
                        } else {
                            dispatch(disableLoading());

                            userDeleteConfirmMessage.fire({
                                title: <strong>{t("Success")}</strong>,
                                html: <i>{t("User deleted!")}</i>,
                                icon: 'success'
                            }).then(() => fetchUsers());
                        }
                    });
                }
            });
    }

    const resetPassword = (id) => {
        userPasswordResetConfirmSwal.fire({
            title: t("Are you sure?"),
            text: t("New password will be generated and sent to user email"),
            icon: "error",
            showCancelButton: true,
        })
            .then(res => {
                if (res.isConfirmed) {
                    //then show loading
                    dispatch(enableLoading());

                    //generate
                    ResetPasswordQuery(id).then((response) => {
                        if (response.status !== 200 && response.status !== 201) {
                            dispatch(disableLoading());

                            let message = response.response.data?.message;

                            userPasswordErrorMessage.fire({
                                title: <strong>{t("Error")}</strong>, html: <i>{t(message)}</i>, icon: 'error'
                            }).then();
                        } else {
                            dispatch(disableLoading());

                            userPasswordConfirmMessage.fire({
                                title: <strong>{t("Success")}</strong>,
                                html: <i>{t("User password changed!")}</i>,
                                icon: 'success'
                            }).then();
                        }
                    });
                }
            });
    }

    const fetchUsers = () => {
        dispatch(enableLoading());

        GetUsersQuery().then((response) => {
            if (response.status === 200) {
                let users = response.data.data;

                let dataTableArray = [];

                let firstGroup = true;
                for (const [, value] of Object.entries(users)) {
                    let groups = "";

                    value.groups.forEach((element) => {
                        if (firstGroup) {
                            firstGroup = false;
                            groups += element.name;
                        } else {
                            groups += ", " + element.name;
                        }
                    });

                    let row = {
                        id: value.id,
                        email: value.email,
                        first_name: value.first_name,
                        last_name: value.last_name,
                        description: value.description,
                        groups: groups,
                        buttons: <ButtonGroup>

                            <button type="button" className="btn btn-warning btn-icon-square-sm"
                                    title={t("Reset password")}
                                    style={{marginRight: "2px"}}
                                    onClick={() => resetPassword(value.id)}
                            >
                                <FontAwesomeIcon icon={faLock}/>
                            </button>

                            <button type="button" className="btn btn-danger btn-icon-square-sm"
                                    title={t("Delete group")}
                                    style={{marginRight: "2px"}}
                                    onClick={() => deleteUser(value.id)}
                            >
                                <FontAwesomeIcon icon={faTrash}/>
                            </button>


                            <button type="button" className="btn btn-primary btn-icon-square-sm"
                                    title={t("Edit group")}
                                    style={{marginRight: "2px"}}
                                    onClick={() => {
                                        setSelectedUser(value);
                                        fetchGroups();
                                        setAddModalShow(true)
                                    }}
                            >
                                <FontAwesomeIcon icon={faPencil}/>
                            </button>


                        </ButtonGroup>
                    }

                    dataTableArray.push(row);
                }

                setDataTableData(dataTableArray);


                dispatch(disableLoading());
            } else {
                dispatch(disableLoading());
            }
        });
    }

    const fetchGroups = () => {
        dispatch(enableLoading());

        GetGroupsQuery().then((response) => {
            if (response.status === 200) {
                let groups = response.data.data;
                setGroups(groups);
                dispatch(disableLoading());
            }
        });
    }

    return (<>
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-header bg-primary">
                        <div className="row">
                            <div className="col-6">
                                <h4 className="card-title text-white"
                                    style={{margin: "6px 0px 0px 0px"}}>{t("Users")}</h4>
                            </div>
                            <div className="col text-end" style={{padding: "0px 0px 0px 0px"}}>
                                <ButtonGroup>
                                    <button type="button" className="btn btn-primary btn-icon-square-sm"
                                            onClick={() => fetchUsers()}>
                                        <FontAwesomeIcon icon={faRotate}/>
                                    </button>
                                    {(currentGroup.is_admin) &&
                                        <Button className="btn btn-primary" style={{padding: "0px 0px 0px 0px"}}
                                                onClick={() => {
                                                    setSelectedUser(null);
                                                    fetchGroups();
                                                    setAddModalShow(true)
                                                }}>
                                            <FontAwesomeIcon icon={faAdd}/> {t("Add user")}
                                        </Button>}
                                </ButtonGroup>
                            </div>
                        </div>

                    </div>

                    <div className="card-body">
                        <div className="table-responsive">
                            <DataTable columns={columns} data={dataTableData} pagination/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <AddUserModal
            show={addModalShow}
            currentGroup={props.currentGroup}
            groups={groups}
            hideFn={() => setAddModalShow(false)}
            loadFn={() => fetchUsers()}
            selectedUser={selectedUser}
        />
    </>);
}