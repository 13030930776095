import React from 'react';
import './StatisticsCard.css';

export default function StatisticsCard(props) {
    return (<>
        <div className={"box " + (props.settings.selected ? "selected" : "")}>
            <h1>{props.title}</h1>
            <h1 className="large-h1">{props.number}</h1>
        </div>
    </>);
}