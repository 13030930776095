import './App.css';
import HiddenWhenLoggedInRoute from "./components/routes/HiddenWhenLoggedInRoute";
import ProtectedRoute from "./components/routes/ProtectedRoute";
import Login from "./pages/Login/Login";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Skeleton from "./pages/Skeleton/Skeleton";
import LogoutPage from "./pages/Logout/Logout";
import Notifications from "./pages/Notifications/Notifications";
import Dashboard from "./pages/Dashboard/Dashboard";
import Nutitee from "./pages/Nutitee/Nutitee";
import Users from "./pages/Users/Users";
import Profile from "./pages/Profile/Profile";
import ANPR from "./pages/ANPR/ANPR";
import moment from 'moment-timezone';
import Statistics from "./pages/Statistics/Statistics";
import Sensors from "./pages/Sensors/Sensors";
import DeviceSettings from "./pages/DeviceSettings/DeviceSettings";
import NutiteePublic from "./pages/NutiteePublic/NutiteePublic";


function App() {
    moment.tz.setDefault(moment.tz.guess());

    return (<BrowserRouter>
        <Routes>
            <Route path="/login" element={<HiddenWhenLoggedInRoute><Login/></HiddenWhenLoggedInRoute>}></Route>
            <Route path="/nutitee-public" element={<NutiteePublic />}></Route>

                <Route path="/" element={<ProtectedRoute><Skeleton/></ProtectedRoute>}>
                    <Route path='' element={<Dashboard/>}/>
                    <Route path='notifications' element={<Notifications/>}/>
                    <Route path='users' element={<Users/>}/>
                    <Route path='profile' element={<Profile/>}/>
                    <Route path='statistics/:deviceID' element={<Statistics/>}/>
                    <Route path='sensors/:deviceID' element={<Sensors/>}/>
                    <Route path='device-settings/:deviceID' element={<DeviceSettings />}/>
                    <Route path='anpr' element={<ANPR/>}/>
                    <Route path='nutitee' element={<Nutitee/>}/>
                    <Route path='nutitee-public' element={<Nutitee/>}/>
                </Route>

            <Route path="/logout" element={<ProtectedRoute><LogoutPage/></ProtectedRoute>}></Route>
        </Routes>
    </BrowserRouter>);
}

export default App;
