import React, {useEffect, useRef, useState} from 'react';

import './Skeleton.css';
import {Link, Outlet, useNavigate} from "react-router-dom";
import LanguageSelect from "../../components/LanguageSelect/LanguageSelect";
import NotificationsIcon from "../../components/NotificationsIcon/NotificationsIcon";
import NavigationDropdown from "../../components/Navigation/NavigationDropdown";
import Navigation from "../../components/Navigation/Navigation";
import TopProfile from "../../components/TopProfile/TopProfile";
import {CONFIG} from "../../config";
import {useSelector} from "react-redux";
import GroupSelect from "../../components/GroupSelect/GroupSelect";
import MeQuery from "../../queries/User/Me";
import Navbar from 'react-bootstrap/Navbar';


export default function Skeleton() {
    const isLoading = useSelector((state) => state.loading.value);
    const [currentGroup, setCurrentGroup] = useState(null);
    const [width, setWidth] = useState();
    const navigate = useNavigate();
    const ref = useRef(null);

    useEffect(() => {
        if (localStorage.getItem("current_group")) {
            setCurrentGroup(JSON.parse(localStorage.getItem("current_group")));
        } else {
            setCurrentGroup(JSON.parse(localStorage.getItem("me")).groups[0]);
        }
        document.body.classList.remove("account-body");
        document.body.classList.remove("login-page");
        setCurrentGroup(JSON.parse(localStorage.getItem("current_group")));
        updateMe();
    }, []);

    useEffect(() => {
        setWidth(ref.current.clientWidth);

        function handleWindowResize() {
            setWidth(ref.current.clientWidth);
        }

        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const updateMe = () => {
        MeQuery().then((result) => {
            if (result.status !== 200) {
                navigate("/logout");
                return;
            }

            let data = result.data.data;

            if (data.groups.length < 1) {
                navigate("/logout");
                return;
            }

            localStorage.setItem('me', JSON.stringify(data));

            setTimeout(updateMe, 10000);
        }, () => {
            if (!localStorage) {
                navigate("/logout");
            }
        });
    }

    return (<>
        {isLoading && <div className="loading">Loading&#8230;</div>}
        <div className="container">
            <div className="topbar">
                <nav className="topbar-main" style={{maxWidth: "1111px"}}>
                    <div className="topbar-left" style={{display: "flex"}}>
                        {currentGroup && width < 992 && <NavigationDropdown currentGroup={currentGroup}/>}
                        {width > 600 && <Link to="/" className="logo">

                            <img
                                src="/images/logo-dark.png"
                                alt="Bercman"
                                className="logo-sm img-responsive"
                            />

                        </Link>}
                    </div>
                    <Navbar className='custom-navbar-right'>
                        {currentGroup && <GroupSelect currentGroup={currentGroup}/>}
                        <LanguageSelect width={width}/>
                        <NotificationsIcon/>
                        <TopProfile/>
                    </Navbar>

                </nav>
                <div ref={ref} className="navbar-custom-menu">
                    <div className="container-fluid" style={{maxWidth: "1111px"}}>
                        {currentGroup && width > 991 && <Navigation currentGroup={currentGroup}/>}
                    </div>
                </div>
            </div>
            <div className="page-wrapper">
                <div className="page-content">
                    <div className="container-fluid">
                        <Outlet/>
                    </div>
                </div>
                <footer className="footer text-center text-sm-left">
                    <div className="boxed-footer">© 2019-{new Date().getFullYear()} Bercman Technologies AS |
                        BMC {CONFIG.VERSION}
                    </div>
                </footer>
            </div>
        </div>
    </>);
}