import L from 'leaflet';
import { createLayerComponent } from '@react-leaflet/core';//from './LeafletExtension/Generic';
import Linemap from './Linemap';

const LinemapLayer = (() => createLayerComponent(function createHeatmapLayer(props, context) {
    const instance = new Linemap(props);

    return { instance, context };
}, function updateHeatmapLayer(instance, {
    gradient,
    latitudeExtractor,
    longitudeExtractor,
    lines,
    fitBoundsOnUpdate,
}) {
    instance.updateHeat({ gradient });
    L.Util.setOptions(instance, {
        latitudeExtractor,
        longitudeExtractor,
        lines
    });

    if(fitBoundsOnUpdate && lines.length)
        instance.fitBounds();

    instance.reset();
}))();

export default LinemapLayer;