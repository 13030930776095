import React from 'react';
import './NavigationDropdown.css';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import MenuIcon from '@mui/icons-material/Menu';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';


export default function Navigation(props) {
    const {t} = useTranslation();
    const dropdownIcon = (<MenuIcon style={{fontSize: "35px"}}/>);

    return (<>
        <Nav>
            <NavDropdown className='custom-dropdown' title={dropdownIcon}>

                <NavDropdown.Item className="nav-dropdown-item" as={Link} to="/">
                    <i className="mdi mdi-desktop-mac-dashboard"/>
                    <span style={{fontSize: "17px"}}>
                        {t(" Dashboard")}
                    </span>
                </NavDropdown.Item>
                <NavDropdown.Divider/>
                <NavDropdown.Item className="nav-dropdown-item" as={Link} to="/notifications">
                    
                    <span style={{fontSize: "17px"}}>
                        <i className="mdi mdi-bell"/> {t(" Notifications")}
                    </span>
                </NavDropdown.Item>
                <NavDropdown.Divider/>
                {props.currentGroup.is_admin ? <>
                        <NavDropdown.Item className="nav-dropdown-item" as={Link} to="/users">
                        <span style={{fontSize: "17px"}}>
                            <i className="mdi mdi-account"/> {t(" Users and groups")}
                        </span>
                        </NavDropdown.Item>
                        <NavDropdown.Divider/>
                    </>


                    : null}

                {props.currentGroup.is_admin ? <NavDropdown.Item className="nav-dropdown-item" as={Link} to="/anpr">
                        <span style={{fontSize: "17px"}}>
                            <i className="mdi mdi-account"/> {t(" ANPR data")}
                        </span>
                    </NavDropdown.Item>

                    : null}

            </NavDropdown>
        </Nav>
    </>)
}