import React from 'react';
import './Navigation.css';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

export default function Navigation(props) {
    const {t} = useTranslation();

    return (<>
        <div id="navigation">
            <ul className="navigation-menu">
                <li>
                    <Link style={{textDecoration: 'none'}} to="/">
                        <i
                            className="mdi mdi-desktop-mac-dashboard"
                            style={{fontSize: "18px"}}
                        />
                        <span>{t("Dashboard")}</span>
                    </Link>
                </li>
		{props.currentGroup.id == 5 &&
                <li>
                    <Link style={{textDecoration: 'none'}} to="/nutitee">
                        <i
                            className="mdi mdi-table"
                            style={{fontSize: "18px"}}
                        />
                        <span>
                                {t("Nutitee")}
                            </span>
                    </Link>
                </li>
		}
                <li>
                    <Link style={{textDecoration: 'none'}} to="/notifications">
                        <i
                            className="mdi mdi-bell"
                            style={{fontSize: "18px"}}
                        />
                        <span>
                                {t("Notifications")}
                            </span>
                    </Link>
                </li>
                {props.currentGroup.is_admin ? <li>
                    <Link style={{textDecoration: 'none'}} to="/users">
                            <span>
                                <i
                                    className="mdi mdi-account"
                                    style={{fontSize: "18px"}}
                                />
                                {t("Users and groups")}
                            </span>
                    </Link>
                </li> : null}
            </ul>
        </div>
    </>);
}
