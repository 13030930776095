import {CONFIG} from "../../config";
import axios from "axios";

async function LogoutQuery() {
    const data = {

    };

    const location = CONFIG.API_URL + "user/logout";
    const settings = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('bearer-token')}`,
        }
    };
    try {
        return await axios.post(location, data, settings);
    } catch (e) {
        return e;
    }
}

export default LogoutQuery;