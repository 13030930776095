import Button from 'react-bootstrap/Button';
import './AddUserModal.css';
import Modal from 'react-bootstrap/Modal';
import {useTranslation} from "react-i18next";
import Form from 'react-bootstrap/Form';
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {disableLoading, enableLoading} from "../../../features/loading/loadingSlice";
import withReactContent from "sweetalert2-react-content";
import swal from 'sweetalert2';
import MultiSelectDropdown from "../../MultiSelectDropdown/MultiSelectDropdown";
import EditGroupQuery from "../../../queries/Groups/EditGroup";
import AddGroupQuery from "../../../queries/Groups/AddGroup";
import AddUserQuery from "../../../queries/User/AddUser";
import EditUserQuery from "../../../queries/User/EditUser";

export default function AddUserModal(props) {
    const {t} = useTranslation();
    const errorMessage = withReactContent(swal);
    const dispatch = useDispatch();
    const [currentGroup] = useState(props.currentGroup);
    const [groupsMultiselect, setGroupsMultiselect] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [selectedGroupsData, setSelectedGroupsData] = useState([]);
    const userAddErrorMessage = withReactContent(swal);

    const [email, setEmail] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [description, setDescription] = useState();
    const [password, setPassword] = useState();

    const generatePassword = () => {
        // Create a random password
        const randomPassword =
            Math.random().toString(36).slice(2) + Math.random().toString(36).slice(2);

        return randomPassword;
    };

    useEffect(() => {
        if(props.selectedUser) {

            setEmail(props.selectedUser.email);
            setFirstName(props.selectedUser.first_name);
            setLastName(props.selectedUser.last_name);
            setDescription(props.selectedUser.description);
            setPassword(null);


            let sg = [];

            for (const [key, value] of Object.entries(props.selectedUser.groups)) {
                sg.push(value.id);
            }

            setSelectedGroupsData(sg);
        }
    }, props.selectedUser);

    useEffect(() => {
        setPassword(generatePassword());
    }, []);

    const cleanAndCloseModal = () => {
        props.loadFn();
        props.hideFn();

        setEmail(null);
        setFirstName(null);
        setLastName(null);
        setDescription(null);
        setPassword(generatePassword());
    }

    useEffect(() => {
        if(props.groups) {

            let dm = [];

            for (const [key, value] of Object.entries(props.groups)) {
                dm.push({'id': value.id, 'value': value.name});
            }

            setGroupsMultiselect(dm);
        }
    }, props.groups);

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }

    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    }

    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
    }
    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    }

    const handleServerResponse = (response) => {
        if (response.status !== 200 && response.status !== 201) {
            dispatch(disableLoading());

            let message = response.response.data?.message;

            if (response.response.data?.data) {
                console.log(response.response.data.data);

                for (const [key, value] of Object.entries(response.response.data.data)) {
                    message += "\n" + value[0];
                }
            }

            userAddErrorMessage.fire({
                title: <strong>{t("Error")}</strong>,
                html: <i>{t(message)}</i>,
                icon: 'error'
            }).then();

            return;
        }

        dispatch(disableLoading);
        cleanAndCloseModal();
    }

    const saveUser = () => {
        dispatch(enableLoading());

        if(!props.selectedUser) {
            AddUserQuery(email, firstName, lastName, password, description, selectedGroups).then((response) => {
                handleServerResponse(response);
            })
        } else {
            EditUserQuery(props.selectedUser.id, email, firstName, lastName, password,null, description, selectedGroups).then((response) => {
                handleServerResponse(response);
            })
        }

    }


    return (
        <Modal {...props} onHide={cleanAndCloseModal}>
            <Modal.Header>
                {!props.selectedUser &&
                <Modal.Title>{t("Add user")}</Modal.Title>
                }
                {props.selectedUser &&
                    <Modal.Title>{props.selectedUser.email}</Modal.Title>
                }
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="nameCtl">
                        <Form.Label>{t("E-mail")}</Form.Label>
                        <Form.Control type="name" placeholder={t("Enter e-mail")} value={email} onChange={handleEmailChange}/>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="nameCtl">
                        <Form.Label>{t("First name")}</Form.Label>
                        <Form.Control type="name" placeholder={t("Enter first name")} value={firstName} onChange={handleFirstNameChange}/>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="nameCtl">
                        <Form.Label>{t("Last name")}</Form.Label>
                        <Form.Control type="name" placeholder={t("Enter last name")} value={lastName} onChange={handleLastNameChange}/>
                    </Form.Group>

                    {!props.selectedUser &&
                    <Form.Group className="mb-3" controlId="nameCtl">
                        <Form.Label>{t("Password (auto generated)")}</Form.Label>
                        <Form.Control type="name" value={password}/>
                    </Form.Group>
                    }

                    {groupsMultiselect &&
                        <MultiSelectDropdown name="Select groups(s)"  options={groupsMultiselect} setSelected={setSelectedGroups}
                                             selectedOptions={selectedGroupsData}/>
                    }

                    <Form.Group className="mb-3" controlId="descriptionCtl">
                        <Form.Label>{t("Description")}</Form.Label>
                        <Form.Control as="textarea" placeholder={t("Enter description")} value={description} onChange={handleDescriptionChange}/>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={cleanAndCloseModal}>
                    {t("Close")}
                </Button>
                <Button variant="primary" onClick={() => saveUser()}>
                    {t("Save")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}