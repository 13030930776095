import React from 'react';
import './Profile.css';
import {useTranslation} from "react-i18next";
import ProfileView from '../../components/ProfileView/ProfileView';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from 'react-router-dom';


export default function Profile() {
    const {t} = useTranslation();

    const navigate = useNavigate();

    return (<>
        <div className="row">
            <div className="col-sm-12">
                <div className="page-title-box">
                    <h4 className="page-title">
                        <FontAwesomeIcon className='back-button' icon={faChevronLeft} onClick={() => navigate(-1)}/>
                        &nbsp; {t("Profile")}
                    </h4>
                </div>
            </div>
        </div>
        <>
            <ProfileView/>
        </>
    </>);
}