import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import LogoutQuery from "../../queries/User/Logout";


export default function LogoutPage() {
    const navigate = useNavigate();

    useEffect(() => {
        LogoutQuery().then();
        localStorage.clear();
        navigate("/login");
    }, []);

    return (<>

    </>);
}