import {Navigate} from "react-router-dom";

const HiddenWhenLoggedInRoute = ({children}) => {
    if (localStorage.getItem('bearer-token') && localStorage.getItem('me')) {
        return <Navigate to="/" replace/>;
    }

    return children;
};

export default HiddenWhenLoggedInRoute