import {createSlice} from '@reduxjs/toolkit'

export const loadingSlice = createSlice({
    name: 'loading', initialState: {
        value: 0,
    }, reducers: {
        enableLoading: (state) => {
            state.value = 1
        }, disableLoading: (state) => {
            state.value = 0
        }
    },
})

export const {
    enableLoading, disableLoading
} = loadingSlice.actions

export default loadingSlice.reducer