import React, {useEffect, useState} from 'react';
import './Dashboard.css';
import DeployedDevices from "../../components/Dashboard/DeployedDevices/DeployedDevices";

export default function Dashboard() {
    const [currentUser, setCurrentUser] = useState();
    const [currentGroup, setCurrentGroup] = useState();

    useEffect(() => {
        setCurrentUser(JSON.parse(localStorage.getItem("me")));
        setCurrentGroup(JSON.parse(localStorage.getItem("current_group")));
    }, []);

    return (<>

        {(currentUser && currentGroup) && <>
            <DeployedDevices currentUser={currentUser} currentGroup={currentGroup}/>
        </>}
    </>);
}