import React, {useEffect, useState} from 'react';
import './GroupSelect.css';
import {useNavigate} from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';

export default function GroupSelect(props) {
    const [userData, setUserData] = useState(null);
    const [currentGroup, setCurrentGroup] = useState(props.currentGroup);
    const navigate = useNavigate();

    useEffect(() => {
        setUserData(JSON.parse(localStorage.getItem("me")));
    }, []);

    const changeGroup = (grp) => {
        localStorage.setItem("current_group", JSON.stringify(grp));
        setCurrentGroup(grp);
        navigate("/login");
    }

    const dropdownTitle = (<span> {currentGroup.name} <i className="mdi mdi-chevron-down"></i> </span>);

    return (<>
        <Nav>
            <NavDropdown className='custom-dropdown' title={dropdownTitle}>
                {(userData && userData.groups) && <>
                    {userData.groups.map((group) => (<NavDropdown.Item onClick={() => changeGroup(group)}>
                        <span> {group.name} </span>
                    </NavDropdown.Item>))}
                </>}
            </NavDropdown>
        </Nav>
    </>);
}