import React from 'react';

import {useTranslation} from "react-i18next";
import Moment from "react-moment";

export default function CustomDailyRangePicker(props) {
    const {t} = useTranslation();

    return (<>
        <div className="row">
            <div className={"col-12 col-sm-12 col-md-6 col-lg-12 col-xl-12"}>
                <div className="card crm-data-card">
                    <div className="text-right"></div>
                    <div className="card-body animated fadeIn fast">
                        <div className="row">
                            <div
                                className={"col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 text-left"}
                                style={{cursor: "pointer"}}
                                onClick={() => props.prev()}>

                                <i className={"mdi mdi-arrow-left"}/>{" "}

                                {t("Back")}
                            </div>
                            <div
                                className={"col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 text-center"}>
                                <Moment format="DD.MM.yyyy">
                                    {props.end}
                                </Moment>
                            </div>
                            {props.end < props.today && <div
                                className={"col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 text-end"}
                                style={{cursor: "pointer"}}
                                onClick={() => props.next()}
                            >
                                {t("Forward")}{" "}
                                <i className={"mdi mdi-arrow-right"}/>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
}