import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import { Row, Col, Button, ButtonGroup, Collapse} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus, faMaximize, faMinimize } from "@fortawesome/free-solid-svg-icons";
import LinemapLayer from "./LinemapLayer";
import './Map.css';

export default function Map(props) {
    const { t } = useTranslation();
    const [isCardOpen, setIsCardOpen] = useState(true);
    const [isMaximized, setIsMaximized] = useState(false);

    const InvalidateSize = () => {
        const map = useMap();
        useEffect(() => {
            map.invalidateSize();
        });
    }

    return (
        <>
            <Row>
                <Col >
                    <div className={ "card " + (!isMaximized ? "" : "maximized-card") }>
                        <div className="card-header bg-primary">
                            <Row>
                                <Col>
                                    <h4 className="card-title text-white">{ t(props.title ?? "Map") }</h4>
                                </Col>
                                <Col xs={3} className="text-end">
                                    <ButtonGroup>
                                        <Button
                                            className="btn btn-primary"
                                            onClick={() => setIsMaximized(!isMaximized)}
                                            aria-controls="collapsableMapCard"
                                            aria-expanded={isCardOpen}
                                        >
                                            { isMaximized && <FontAwesomeIcon icon={faMaximize}/> }
                                            { !isMaximized && <FontAwesomeIcon icon={faMinimize}/> }
                                        </Button>
                                        { !isMaximized &&
                                            <Button
                                                className="btn btn-primary"
                                                onClick={() => setIsCardOpen(!isCardOpen)}
                                                aria-controls="collapsableMapCard"
                                                aria-expanded={isCardOpen}
                                            >
                                                { isCardOpen && <FontAwesomeIcon icon={faMinus}/> }
                                                { !isCardOpen && <FontAwesomeIcon icon={faPlus}/> }
                                            </Button>
                                        }
                                    </ButtonGroup>
                                </Col>
                            </Row>
                        </div>
                        <Collapse in={isCardOpen}>
                            <div id="collapsableMapCard">
                                <div className="card-body">
                                    <MapContainer zoom={11} center={[58.36394, 26.76912]} scrollWheelZoom={true}>
                                        { props.line && <LinemapLayer
                                            fitBoundsOnLoad={props.fitBoundsOnLoad ?? false}
                                            fitBoundsOnUpdate
                                            lines={props.data ?? []}
                                            longitudeExtractor={m => m[1]}
                                            latitudeExtractor={m => m[0]}
                                        /> }
                                        <TileLayer
                                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        />
                                        <InvalidateSize/>
                                    </MapContainer>
                                </div>
                            </div>
                        </Collapse>
                    </div>
                </Col>
            </Row>
        </>
    );
}