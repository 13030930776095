import {CONFIG} from "../../config";
import axios from "axios";

async function GetDevicesQuery(group) {
    const location = CONFIG.API_URL + "device/get/" + group;
    const settings = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('bearer-token')}`,
        }
    };

    try {
        return await axios.get(location, settings);
    } catch (e) {
        return e;
    }
}

export default GetDevicesQuery;